import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody,Button, Media } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { useStateMachine } from "little-state-machine";
import { updateUser, updateBookings } from "../../../../utils/updateActions";
import axios from 'axios';
import VehicleModal from '../../../../components/Modals/VehicleModal';
import PersonnelModal from '../../../../components/Modals/PersonnelModal';
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import ParcelsChart from "./ParcelsChart";
import SalesChart from "./SalesChart";
import DailySummary from "./DailySummary";
import WeeklySummary from "./WeeklySummary";
import MonthlySummary from "./MonthlySummary";
import Barchart from "../../Teller/Dashboard/Barchart";
import BookingSummary from "../../Owner/Dashboard/BookingSummary";
import ParcelSummary from "../../Owner/Dashboard/ParcelSummary";
import ParcelChart from "../../Owner/Dashboard/ParcelChart";
import BookingChart from "../../Owner/Dashboard/BookingChart";


function Dashboard({ t }) {
  const vehicle = {};
  const personnel = {};
 
  const { state } = useStateMachine(updateUser, updateBookings);
  const [breadcrumbItems] = useState([
    { title: state?.user?.data?.data?.agencyName, link: "#" },
    { title: t("Dashboard"), link: "#" },


  ]);

  const [modal, setModal] = useState({
    modal_static: false,
  });
  const [pmodal, setPmodal] = useState({
    modal_static: false,
  });

 
  useEffect(() =>{
    document.body.setAttribute('style', 'background: #f8f8f8!important;')
    
  }, [])
 

  const fetchVehicles = async () => {
    try {
      await axios.get("api/v1/vehicles");
    } catch (err) {

    }
  };
  const fetchPersonnel = async () => {
    try {
      await axios.get("api/v1/Personnel");

    } catch (err) {


    }
  };

  const showModal = () => {

    setModal({ modal_static: true });

  }

  const showPmodal = () => {

    setPmodal({ modal_static: true });

  }



  
  return(  <React.Fragment>
   { state.user.data.data.permissions === 'CRUD_admins'?  <div className="page-content">
      <Container>

        <Breadcrumbs title={t('Dashboard')} breadcrumbItems={breadcrumbItems} />
        <Row>
         

          <Col xl={4}>
            <Card>
              <CardBody>
                <Media>
                  <Media body className="overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">{t('users')}</p>
                    <h4 className="mb-0">44756</h4>
                  </Media>

                </Media>
              </CardBody>
            </Card>
          </Col>
          <Col xl={4}>
           <ParcelSummary/>
          </Col>

          <Col xl={4}>
           <BookingSummary/>
          </Col>
         
        </Row>

        <Row className="">
          <Col xl={12}>

            <Card>
              <CardBody>
                <BookingChart />
              </CardBody>
            </Card>

          </Col>
          <Col xl={12}>

            <Card>
              <CardBody>
                <ParcelChart />
              </CardBody>
            </Card>

          </Col>


        </Row>

        <Row>



        </Row>

      </Container>
    </div>: (state.user.data.data.permissions === 'CRUD_agency'? <div className="page-content">
        <Container>
          <Breadcrumbs
            title={t("Dashboard")}
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
              <Col xl={4}>
                <DailySummary/>
              </Col>
              <Col xl={4}>
                <WeeklySummary/>
              </Col>
              <Col xl={4}>
                <MonthlySummary/>
              </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <div className="float-right d-none d-md-inline-block">

                    <Button size="md" className="mr-2" color="primary" type="button" onClick={() => {
                      showModal();
                    }}>{t('Add Vehicle')}</Button>
                    <Button size="md" color="primary" type="button" onClick={() => {
                      showPmodal();
                    }}>{t('Add Personnel')}</Button>

                  </div>
                  <SalesChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <ParcelsChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <VehicleModal getModal={setModal} modal={modal} title={t('Add Vehicle')} vehicle={vehicle} fetchVehicles={fetchVehicles} buttonText={t('Add')} controlText={'Add'}
          />
          <PersonnelModal getModal={setPmodal} modal={pmodal} title={t('Add Pesonnel')} personnel={personnel} fetchPersonnel={fetchPersonnel} buttonText={t('Edit')} controlText={'Add'}
          />
        </Container>
      
      </div>  :  <div className="page-content">
        <Container>
          <Breadcrumbs
            title={t("ticket_chart")}
            breadcrumbItems={breadcrumbItems}
          />
          <Card>
            <CardBody>
              <Row>
                <Col xl={12}>
                  <Barchart />
                </Col>
              </Row>
            </CardBody>
          </Card>

        </Container>
      </div>)}
   
 
    
     
    </React.Fragment>)} 
    
 

export default withNamespaces()(Dashboard);
