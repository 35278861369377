import React from "react";
import { withRouter } from "react-router-dom";
import { } from "../../store/actions";
import { useStateMachine } from "little-state-machine";
import { updateUser } from "../../utils/updateActions";
import SimpleBar from "simplebar-react";
import SidebarContent from "./SidebarContent";


function Sidebar (props) {
  
const {state} = useStateMachine(updateUser);
 
 
      return (
        <React.Fragment>
          <div className="vertical-menu">

            <div data-simplebar className="h-100">
              {props.type !== "condensed" ? (
                <SimpleBar style={{ maxHeight: "100%" }}>
                  <SidebarContent permissions={state?.user?.data?.data?.permissions}
                  personnelType={state?.user?.data?.data?.personnelType}
                   />
                </SimpleBar>
              ) : <SidebarContent permissions={state?.user?.data?.data?.permissions} 
              personnelType={state?.user?.data?.data?.personnelType}
              />}
            </div>
          </div>
        </React.Fragment>)
    }
   


export default (withRouter(Sidebar));
