import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import logo from "../../../assets/images/transapp-logo.svg";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";

function ResetPassword({ t }) {
  const history = useHistory();
  const {token} = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData) => {
    console.log(formData);
    try {
      setLoading(true);
      const { data } = await axios.post(`api/v1/admin-reset/${token}`, formData);
      
      if (data.error) {
        toast.error(data.error);
        setLoading(false);
      } else {
        toast.success(data.message);

        history.push("/signin");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div
        className="min-vh-100 d-flex flex-column opacity-mask"
        data-opacity-mask="rgba(0, 0, 0, 0.4)"
      >
        <div className="container my-auto">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-5 mx-auto my-4">
              <div className="panel header_2 center">
                <figure>
                  <a href="#0">
                    <img src={logo} width="86" height="80" alt="" />
                  </a>
                </figure>

                <form
                  className="input_style_1"
                  onSubmit={handleSubmit(onSubmit)}
                >
                 
                <label htmlFor="password">{t("password")}</label>
                <div class="row">
                  <div class="input-group mb-3 col-lg-12">

                    <input
                      type={isPasswordVisible ?  "text" : "password"}
                      name="password" id="password"
                      className="form-control"
                      {...register("password", {
                        required: true,
                        minLength: 6
                      })}

                    />
                    <div className="input-group-prepend bg-white">
                      <span className="input-group-text border-left-0 rounded-right bg-white" id="basic-addon1"><i className={isPasswordVisible ?  "fas fa-eye" : "fas fa-eye-slash"} onClick={togglePassword}></i></span>
                    </div>
                  </div>
                </div>
                  {errors.password && (
                    <p style={{ color: "red" }}>{t("password_err")}</p>
                  )}

                  {loading ? (
                    <button type="submit" className="btn_1 full-width" disabled>
                      <Spinner
                        as="span"
                        variant="warning"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="grow"
                      />
                      {t("submitting")}
                    </button>
                  ) : (
                    <button type="submit" className="btn_1 full-width">
                      {t("reset_password")}
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withNamespaces()(ResetPassword);
