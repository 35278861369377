import React, { useState, useEffect } from "react";

import axios from "axios";
import moment from "moment";

import { Card, CardBody, Media } from "reactstrap";

import { withNamespaces } from "react-i18next";

const WeeklySummary = ({ t }) => {
 
  const [data, setData] = useState([]);
  const [sales, setSales] = useState(0);
  const [show, setShow] = useState(true);
  let weekTotal = 0;
  //var date = new Date()
  const defaultWeek =  moment(new Date()).format("W");
 
  
  const [icons] = useState({
    reports: [
      { icon: "ri-stack-line", title: t('today') },
      { icon: "ri-store-2-line", title: t('sales_week') },
      { icon: "ri-briefcase-4-line", title: t('parcels') },
    ],
  });
  const fetchBookingsByAgency = async () => {

    try {
      const { data } = await axios.get(`/api/v1/bookings`);

      const values = Object.values(data);
      setData(values[0]);
      

    } catch (err) { }
  };

  useEffect(() => {
    fetchBookingsByAgency();
    
    
  }, []);

  getDefaultWeekSalesTotal();

  function getDefaultWeekSalesTotal() {
   
    const filterWeek = data.filter((booking) => {
    
      return (
        booking.week ===
        moment(new Date()).format("W")
      );
    });

    for (let i = filterWeek.length - 1; i >= 0; i--) {


      let total = filterWeek[i].price;

      weekTotal += total;

    }


  }
  function getWeekSalesTotal(event) {
   
    let total = 0;
    let wkTotal = 0;
    const filterWeek = data.filter((booking) => {
    
      return (
        booking.week ===
        event.target.value
      );
    });

    for (let i = filterWeek.length - 1; i >= 0; i--) {


       total = filterWeek[i].price;

      wkTotal += total;
      
    }
    setShow(false)
    setSales(wkTotal)
  }

  return (

    <Card>
      <CardBody>
      <div className="float-right">
          <select
            name="month-list"
            id="month-list"
            onChange={getWeekSalesTotal}
            className="custom-select custom-select-sm" >
            <option defaultValue>{defaultWeek}</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
            <option value="32">32</option>
            <option value="33">33</option>
            <option value="34">34</option>
            <option value="35">35</option>
            <option value="36">36</option>
            <option value="37">37</option>
            <option value="38">38</option>
            <option value="39">39</option>
            <option value="40">40</option>
            <option value="41">41</option>
            <option value="42">42</option>
            <option value="43">43</option>
            <option value="44">44</option>
            <option value="45">45</option>
            <option value="46">46</option>
            <option value="47">47</option>
            <option value="48">48</option>
            <option value="49">49</option>
            <option value="50">50</option>
            <option value="51">51</option>
            <option value="52">52</option>

          </select>
        </div>
        <Media>
          <Media body className="overflow-hidden">
            <p className="text-truncate font-size-14 mb-2">
              {icons.reports[1].title}
            </p>
            {show ? <h4 className="mb-0">{weekTotal}</h4> : <h4 className="mb-0">{sales}</h4>}
            
          </Media>
          
        </Media>
      </CardBody>
    </Card>
  );
};
export default withNamespaces()(WeeklySummary);
