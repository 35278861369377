import React from "react";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
	component: Component,
	layout: Layout,
  PublicRoutes,
	isAuthProtected,
 // isSuperAdminRoute,
 // isOwnerAuthProtected,
 // isAgencyAdminRoute,
 // isTellerProtected,
//  tellerRoute,
	...rest
}) => (
		<Route
			{...rest}
			render={props => {
       
    
        if (isAuthProtected && !localStorage.getItem("authUser")) {
					return (
						<Redirect to={{ pathname: "/signin", state: { from: props.location } }} />
					);
				}

        
				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

export default AppRoute;

