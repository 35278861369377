import React, { useState } from "react";

import {
 
  Container,
 
} from "reactstrap";

import { withNamespaces } from "react-i18next";
import moment from "moment";
import { useStateMachine } from "little-state-machine";
import { updateAction, updateBooking } from "../../../utils/updateActions";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

function BookingSummary({t}) {
  document.body.setAttribute('style', 'background: #f8f8f8!important;')
 
  const { state } = useStateMachine(updateAction, updateBooking);

  const [breadcrumb] = useState([
    { title: state?.user?.data?.data?.agencyName, link: "#" },
    { title: t('ticket_summary'), link: "#" },
  ]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={false}>
          <Breadcrumbs title={t('ticket_summary')} breadcrumbItems={breadcrumb} />
         
          <div className="row">
            <div className="col-md-9 col-lg-6 col-xl-5 mx-auto my-4 box">
              <h3>{t('ticket_summary')}</h3>

              <ul>
                <li>{t('ticket_number')}: <span> {state.booking.booking.data.ticketNumber} </span></li>
                <li>{t('name')}: <span> {state.booking.booking.data.name}</span></li>
                <li> {t('id_card')}: <span> {state.booking.booking.data.idCardNo}</span></li>
                <li>{t('phone')}: <span>{state.booking.booking.data.phoneNumber}</span></li>
                <li>{t('from')}: <span>{state.booking.booking.data.departureCity}</span></li>
                <li>{t('to')}: <span>{state.booking.booking.data.arrivalCity}</span></li>
              
                <li>{t('journey_date')}: <span>{moment(
                  state.booking.booking.data.journeyDate
                ).format("DD-MM-YYYY")}: </span></li>
                
                <li>{t('time')}: <span>{moment(state.booking.booking.data.departureTime).format('LT')}</span></li>
                <li>{t('fare')}: <span>{state.booking.booking.data.price}</span></li>
                <li>{t('seat')}: <span>{state.booking.booking.data.seat}</span></li>
                

              </ul>
              <a href="/print-ticket" className="btn-buy">{t('print_ticket')}</a>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default  withNamespaces() (BookingSummary);
