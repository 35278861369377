export  const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#fff" : state.isFocused ? '#fff' : '#000',
    backgroundColor: state.isSelected ? "#bcc015" : state.isFocused ? '#bcc015' : '#fff',
    ':active': {
      ...defaultStyles[':active'],
      backgroundColor: state.isSelected
        ? '#162f27'
        : state.isFocused ? '#bcc015'
          : '#162f27'

    },
  }),

  control: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: "#fff",

    borderColor: '#ccc',
    boxShadow: "#bcc015",
    ':hover': {
      ...defaultStyles[':hover'],
      borderColor: state.isSelected
        ? '#162f27'
        : '#bcc015'

    },
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),
};