import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { Container } from "reactstrap";
import moment from "moment";
import { withNamespaces } from "react-i18next";

const BarChart = ({ t }) => {
  const [chartData, setChartData] = useState({});
  const [data, setData]= useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const Chart = () => {
      let tripTime = [];
      let tripSeats = [];
      let tripReserved = [];
  
      axios
        .get("api/v1/trips")
        .then((res) => {
          setData(res.data.data)
          for (const dataObj of res.data.data) {
            if (
              moment(new Date()).format("DD-MM-YYYY") ===
              moment(dataObj.journeyDate).format("DD-MM-YYYY")
            ) {
              tripTime.push(dataObj.departure);
              tripReserved.push(dataObj.reserved);
              tripSeats.push(dataObj.seats);
            }
          }
          //const format_time = tripTime.sort().map(time => moment(time).format("LT"));
          //const format_time = tripTime.sort().map(time => time);
          setChartData({
            labels: tripTime,
            datasets: [
              {
                label: t('tickets_reserved'),
                data: tripReserved,
                backgroundColor: "rgba(117, 142, 79, 0.3)",
  
                borderColor: "rgba(117, 142, 79, 1)",
  
                borderWidth: 1,
              },
              {
                label: t('tickets_available'),
                data: tripSeats,
                backgroundColor: "rgba(246, 174, 45, 0.3)",
  
                borderColor: "rgba(246, 174, 45, 1)",
  
                borderWidth: 1,
              },
            ],
          });
        })
       
        .catch((err) => {
         
        });
        setLoading(false);
    };
    Chart();
  }, [t]);

  return data.length === 0? (<React.Fragment>
    <div className="page-content">
      <Container className="">
        <div className="loader-outer-bar">
          <div className="loader-inner">
          <p>{t('no_trips')}.</p>
          </div>
        </div>
      </Container>
    </div>
  </React.Fragment>):
  loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container className="">
          <div className="loader-outer-bar">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) :  (
    <div className="App">
      <h1>{t("trips_today")}</h1>
      <div>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            title: { text: t("chart_title"), display: true },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }}
        />
      </div>
    </div>
  );
};
export default withNamespaces()(BarChart);
