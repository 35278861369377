
import Agency from "../pages/Admin/Owner/Agency";
import OwnerDashboard from "../pages/Admin/Owner/Dashboard";
import Signin from "../pages/Admin/Authentication/Signin";

import BookTrip from "../pages/Admin/Teller/Bookings";
import BookingsDatabase from "../pages/Admin/Owner/BookingsDatabase";
import BookingsByAgency from "../pages/Admin/Owner/Bookings";
import TellerDashboard from "../pages/Admin/Teller/Dashboard";
import AgencyBookings from "../pages/Admin/Owner/AgencyBookings";
import ResetHelper from "../pages/Util/ResetHelper";
import ForgotPassword from "../pages/Admin/Authentication/Forgot";
import ResetPassword from "../pages/Admin/Authentication/Reset";
import PendingBookings from "../pages/Admin/Teller/PendingBookings";
import TripBookings from "../pages/Admin/Teller/TripBookings";
import Personnel from "../pages/Admin/Agency/Personnel";
import Trips from "../pages/Admin/Agency/Trips";
import Vehicles from "../pages/Admin/Agency/Vehicles";
import Locations from "../pages/Admin/Agency/Locations";
import BookingSummary from "../pages/Admin/Teller/BookingSummary";
import Parcels from "../pages/Admin/Teller/Parcels";
import RegisterParcel from "../pages/Admin/Teller/RegisterParcel";
import RegisteredParcelSummary from "../pages/Admin/Teller/ParcelSummary";



import Dashboard from "../pages/Admin/Agency/Dashboard";

const authProtectedRoutes = [{ path: "/booking-summary", component: BookingSummary }, { path: "/agencies", component: Agency },
{ path: "/all-bookings", component: BookingsDatabase },
{ path: "/bookings-by-agency", component: AgencyBookings },
{ path: "/owner-dashboard", exact: true, component: OwnerDashboard },
{ path: "/agency-bookings", component: BookingsByAgency },
{ path: "/teller-dashboard", exact: true, component: TellerDashboard },
{ path: "/trips", component: Trips },
{ path: "/tickets", component: BookTrip },
{ path: "/list-trip-bookings", component: TripBookings },
{ path: "/register-parcel", component: RegisterParcel },
{ path: "/parcel-summary", component: RegisteredParcelSummary },
{ path: "/discharge-parcel", component: Parcels },
{ path: "/locations", component: Locations },
{ path: "/vehicles", component: Vehicles },

{ path: "/pending-bookings-list", component: PendingBookings },
  { path: "/trips-list", component: Trips },


{ path: "/agency-personnel", component: Personnel },
{ path: "/dashboard", component: Dashboard },
  //{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];


const publicRoutes = [


  { path: "/admin-forgot", component: ForgotPassword },
  { path: "/api/v1/admin-reset/:token", component: ResetPassword },
  { path: "/reset-bridge", component: ResetHelper },

  { path: "/signin", exact: true, component: Signin },
  { path: "/", name: "Home", exact: true, component: Signin },




  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/index" /> },
];





export {
  authProtectedRoutes,
  publicRoutes,
  // agencyAdminRoute,
  // superAdminRoute,
  //tellerRoute,
};
