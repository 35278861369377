import React from 'react'

import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import { withNamespaces } from "react-i18next";
import logo from "../../assets/images/transapp-logo.svg";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useState } from 'react';

function ForgotPassword({t}) {
  const history = useHistory();
  const {token} = useParams();
  const [isVisible, setIsVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();



  const onSubmitForgot = async (formData) => {
    try {
    
      const { data } = await axios.post(`api/v1/admin-forgot`, formData);
      if (data.error) {
        toast.error(data.error);
       
      } else {
        toast.success(data.message);
        history.push('/reset-bridge')
        setIsVisible(true)
      }
    } catch (error) {
    
      toast.error(error.response.data.message);
    }
  };

  const onSubmitReset = async (formData) => {
  
    try {
      
      const { data } = await axios.post(`${token}`, formData);
      
      if (data.error) {
        toast.error(data.error);
       
      } else {
        toast.success(data.message);

        history.push('/signin');
      }
    } catch (error) {
     
      toast.error(error.response.data.message);
    }
  };
  return (
    <div
    className="min-vh-100 d-flex flex-column opacity-mask"
    data-opacity-mask="rgba(0, 0, 0, 0.4)"
  >
    <div className="container my-auto">
      <div className="row">
        <div className="col-md-9 col-lg-7 col-xl-5 mx-auto my-4">
          <div className="panel header_2 center">
            <figure>
              <a href="#0">
                <img src={logo} width="86" height="80" alt="" />
              </a>
            </figure>

            <form
              className="input_style_1"
              onSubmit={isVisible ?  handleSubmit(onSubmitReset) : handleSubmit(onSubmitForgot)}
            >
              {!isVisible ? (<div className="form-group">
                <label htmlFor="email_address">{t("email_address")}</label>
                <input
                  type="email"
                  name="email_address"
                  id="email_address"
                  className="form-control"
                  {...register("email", {
                    required: true,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                  {errors.email && (
                <p style={{ color: "red" }}>{t("email_err")}</p>
              )}
              </div>): (null )}
             
              {isVisible ? (  <>
                <label htmlFor="password">{t("password")}</label>
                <div class="row">
                  <div class="input-group mb-3 col-lg-12">

                    <input
                      type={isPasswordVisible ?  "text" : "password"}
                      name="password" id="password"
                      className="form-control"
                      {...register("password", {
                        required: true,
                        minLength: 6
                      })}

                    />
                    <div className="input-group-prepend bg-white">
                      <span className="input-group-text border-left-0 rounded-right bg-white" id="basic-addon1"><i className={isPasswordVisible ?  "fas fa-eye" : "fas fa-eye-slash"} onClick={togglePassword}></i></span>
                    </div>
                  </div>
                </div></>
              
                 ): (null)}

              { !isVisible  ? (
                <button type="submit" className="btn_1 full-width" >
                  {t("submit")}
                </button>
              ) : (
                <button type="submit" className="btn_1 full-width">
                 {t("reset_password")}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default withNamespaces() (ForgotPassword);