import React from "react";
import { Modal, Button } from "react-bootstrap";
import { withNamespaces} from "react-i18next";

const DeleteConfirmation = ({
  showModal,
  hideModal,
  confirmModal,
  message,
  t
}) => {
  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('delete_confirmation')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-danger">{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>
        {t('cancel')}
        </Button>
        <Button type="submit" variant="danger" onClick={confirmModal}>
          {t('Delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withNamespaces()(DeleteConfirmation);
