import React, { useState, useEffect } from "react";

import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { MDBDataTable } from "mdbreact";
//import "./datatables.scss";
import { withNamespaces } from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";

function BookingsDatabase({ t }) {
  document.body.setAttribute('style', 'background: #f8f8f8!important;')
  const [loading, setLoading] = useState(true);
 
 
  const [breadcrumb] = useState([

    { title: t('owner'), link: "#" },
    { title: t('Bookings'), link: "#" },
  ]);

  const [data, setData] = useState([]);

  useEffect(() => {


    fetchAllBookings();
  }, []);
  const fetchAllBookings = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/bookings/all-bookings`
      );

      const values = Object.values(data);
      setData(values[0]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);

    }
  };

  const exportToPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#table-to-xls' })

    doc.save('table.pdf')
  }

  const tripData = {
    columns: [
      {
        label: t('Agency'),
        field: "agency",
      },
      {
        label: t('name'),
        field: "name",
      },
      {
        label: t('id_card'),
        field: "idcard",
      },
      {
        label: t('phone'),
        field: "phone",
      },

      {
        label: t('from'),
        field: "from",
      },
      {
        label: t('to'),
        field: "to",
      },
      {
        label: t('departure_time'),
        field: "departure",
      },

      {
        label: t('journey_date'),
        field: "journeyDate",
      },
      {
        label: t('seat_no'),
        field: "seat",
      },

      {
        label: t('fare'),
        field: "price",
      },


    ],
    rows: data.map((trip) => {
      return {
        agency: trip.agencyName,
        name: trip.name,
        idcard: trip.idCardNo,
        phone: trip.phoneNumber,
        from: trip.departureCity,
        to: trip.arrivalCity,
        departure: moment(trip.departureTime).format('LT'),

        journeyDate: moment(trip.journeyDate).format("DD-MM-YYYY"),
        seat: trip.seat,

        price: trip.price,



      };
    }),
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={t('Bookings')} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card >
                <CardBody className="pt-3">

                  <Nav tabs className="nav-tabs-custom mb-4">
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button btn btn-primary mb-3"
                      table="table-to-xls"
                      filename="tablexls"
                      sheet="tablexls"
                      buttonText=  { (t('export_excel'))} />
                    <div className="ml-4">
                      <Link
                        to="#"
                        onClick={() => { exportToPdf() }
                        }
                        className="btn btn-primary mb-3"
                      >
                      { (t('export_pdf'))}
                      </Link>
                    </div>
                  </Nav>

                  <MDBDataTable exportToCSV noBottomColumns searchLabel={t('Search')}  responsive data={tripData} className="mt-4" />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card hidden>
                <CardBody>

                  <div className=" mt-3" >

                    <Table id="table-to-xls"

                      className="table table-centered table-responsive datatable dt-responsive nowrap  "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead className="thead-light">
                        <tr>

                          <th style={{ width: "120px" }}>{t('Agency')}</th>
                          <th style={{ width: "120px" }}>{t('name')}</th>
                          <th style={{ width: "120px" }}>{t('id_card')}</th>
                          <th style={{ width: "120px" }}>{t('phone')}</th>
                          <th style={{ width: "120px" }}>{t('from')}</th>
                          <th style={{ width: "120px" }}>{'to'}</th>
                          <th style={{ width: "120px" }}>{t('departure_time')}</th>
                          <th style={{ width: "120px" }}>{t('journey_date')}</th>
                          <th style={{ width: "120px" }}>{t('seat_no')}</th>
                          <th style={{ width: "120px" }}>{t('price')}</th>



                        </tr>
                      </thead>
                      <tbody>

                        {
                          data.map((booking, key) =>

                            <tr key={booking.id}>
                              <td>{booking.agencyName}</td>
                              <td>{booking.name}</td>
                              <td>{booking.idCardNo}</td>
                              <td>{booking.phoneNumber}</td>
                              <td>{booking.departureCity}</td>
                              <td>{booking.arrivalCity}</td>
                              <td>{booking.departureTime}</td>
                              <td>{booking.journeyDate}</td>
                              <td>{booking.seat}</td>
                              <td>{booking.price}</td>


                            </tr>
                          )
                        }

                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(BookingsDatabase);
