import React, { useState, useEffect, useCallback } from "react";
import { Bar } from "react-chartjs-2";
import { Container } from "reactstrap";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";

import { withNamespaces } from "react-i18next";
let past_year;
const currentYear = new Date().getFullYear();
const previousYear = currentYear - 1;

const SalesChart = ({ t }) => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const [data, setData]= useState([]);
  const [data2, setData2]= useState([]);
  //let past_year;
  //const currentYear = new Date().getFullYear();
  //const previousYear = currentYear - 1;

  const fetchBookingsByAgency =  useCallback( async () => {
    try {
      const { data } = await axios.get(`/api/v1/bookings/prev-year`);

      const values = Object.values(data);
      setData(values[0]);
      past_year = values;
    } catch (err) {}
    try {
      const { data } = await axios.get(`/api/v1/bookings`);

      const values = Object.values(data);
      setData2(values[0]);
      if (data !== null) {
        let jan = 0;
        let feb = 0;
        let mar = 0;
        let apr = 0;
        let may = 0;
        let jun = 0;
        let jul = 0;
        let aug = 0;
        let sept = 0;
        let oct = 0;
        let nov = 0;
        let dec = 0;
        let jan_past = 0;
        let feb_past = 0;
        let mar_past = 0;
        let apr_past = 0;
        let may_past = 0;
        let jun_past = 0;
        let jul_past = 0;
        let aug_past = 0;
        let sept_past = 0;
        let oct_past = 0;
        let nov_past = 0;
        let dec_past = 0;

        let sales = [];
        let sales_past = [];

        for (const element of Object.entries(values)) {
          let value = element[1];

          for (let i = value.length - 1; i >= 0; i--) {
           
            if (value[i].month === "Jan") {
              let total = value[i].price;
              jan += total;
            }

            if (value[i].month === "Feb") {
              let total = value[i].price;
              feb += total;
            }

            if (value[i].month === "Mar") {
              let total = value[i].price;
              mar += total;
            }

            if (value[i].month === "Apr") {
              let total = value[i].price;
              apr += total;
            }

            if (value[i].month === "May") {
              let total = value[i].price;
              may += total;
            }

            if (value[i].month === "Jun") {
              let total = value[i].price;
              jun += total;
            }

            if (value[i].month === "Jul") {
              let total = value[i].price;
              jul += total;
            }

            if (value[i].month === "Aug") {
              let total = value[i].price;
              aug += total;
            }

            if (value[i].month === "Sept") {
              let total = value[i].price;
              sept += total;
            }

            if (value[i].month === "Oct") {
              let total = value[i].price;
              oct += total;
            }

            if (value[i].month === "Nov") {
              let total = value[i].price;
              nov += total;
            }

            if (value[i].month === "Dec") {
              let total = value[i].price;
              dec += total;
            }
          }
        }

        for (const element of Object.entries(past_year)) {
          let value = element[1];

          for (let i = value.length - 1; i >= 0; i--) {
  
            if (value[i].month === "Jan") {
              let total = value[i].price;

              jan_past += total;
            }

            if (value[i].month === "Feb") {
              let total = value[i].price;
              feb_past += total;
            }

            if (value[i].month === "Mar") {
              let total = value[i].price;
              mar_past += total;
            }

            if (value[i].month === "Apr") {
              let total = value[i].price;
              apr_past += total;
            }

            if (value[i].month === "May") {
              let total = value[i].price;
              may_past += total;
            }

            if (value[i].month === "Jun") {
              let total = value[i].price;
              jun_past += total;
            }

            if (value[i].month === "Jul") {
              let total = value[i].price;
              jul_past += total;
            }

            if (value[i].month === "Aug") {
              let total = value[i].price;
              aug_past += total;
            }
            if (value[i].month === "Sept") {
              let total = value[i].price;
              sept_past += total;
            }

            if (value[i].month === "Oct") {
              let total = value[i].price;
              oct_past += total;
            }

            if (value[i].month === "Nov") {
              let total = value[i].price;
              nov_past += total;
            }

            if (value[i].month === "Dec") {
              let total = value[i].price;
              dec_past += total;
            }
          }
        }

        sales.push(jan, feb, mar, apr, may, jun, jul, aug, sept, oct, nov, dec);
        sales_past.push(
          jan_past,
          feb_past,
          mar_past,
          apr_past,
          may_past,
          jun_past,
          jul_past,
          aug_past,
          sept_past,
          oct_past,
          nov_past,
          dec_past
        );

        const static_labels = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];

        setChartData({
          labels: static_labels,
          datasets: [
            {
              label: currentYear,
              data: sales,

              backgroundColor: "rgba(117, 142, 79, 0.3)",

              borderColor: "rgba(117, 142, 79, 1)",

              borderWidth: 1,
            },
            {
              label: previousYear,
              data: sales_past,

              backgroundColor: "rgba(246, 174, 45, 0.3)",

              borderColor: "rgba(246, 174, 45, 1)",

              borderWidth: 1,
            },
          ],
        });
      }
      setLoading(false);
    } catch (err) {}
  }, []);
  useEffect(() => {
    fetchBookingsByAgency();
  }, [fetchBookingsByAgency]);

  return data.length === 0 && data2.length === 0? (<React.Fragment>
    <div className="page-content">
      <Container className="">
        <div className="loader-outer-bar">
          <div className="loader-inner">
          <p>{t('no_booking')}.</p>
          </div>
        </div>
      </Container>
    </div>
  </React.Fragment>):
  loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container className="">
          <div className="loader-outer-bar">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <div className="">
      <div>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            title: { text: t('ticket_income'), display: true },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }}
        />
      </div>
    </div>
  );
};
export default withNamespaces()(SalesChart);
