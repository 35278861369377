import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
//import "./datatables.scss";
import moment from "moment";
import { withNamespaces } from 'react-i18next';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";
import { useStateMachine } from "little-state-machine";
import { updateUser } from "../../../utils/updateActions";
import VehicleModal from "../../../components/Modals/VehicleModal";
import DeleteConfirmation from "../../../components/Modals/DeleteConfirmation";

function Vehicles({t}) {
  const [loading, setLoading] = useState(true);
  const {state} = useStateMachine(updateUser);
  const [breadcrumb] = useState([
    { title: state?.user?.data?.data?.agencyName, link: "#" },
    { title: t('Vehicles'), link: "#" },
  ]);
  const [data, setData] = useState([]);
  const [vehicle, setVehicle] = useState({});
  const vehicleRef = useRef({});
  const [modal, setModal] = useState({
    modal_static: false,
  });
  const [editModal, setEditModal] = useState({
    modal_static: false,
  });
  const [setAlert] = useState({
    isAlertOpen: false,
  });

  const [deleteMessage, setDeleteMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  useEffect(() => {
    fetchVehicles();
  }, []);

  const fetchVehicles = async (req, res) => {
    try {
      const { data } = await axios.get("api/v1/vehicles");

      const values = Object.values(data);
      setData(values[0]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
     
    }
  };

  const showModal = () => {
    const vehicle = {};
    getCurrentVehicle(vehicle);

    setModal({ modal_static: true });
    setAlert({ isAlertOpen: false });
  };

  const openEditModal = () => {
    setEditModal({ modal_static: true });
    setAlert({ isAlertOpen: false });
  };

  const showDeleteModal = () => {
    setDisplayConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const deleteVehicle = async (formData) => {
    try {
      const { data } = await axios.delete(
        `api/v1/vehicles/${vehicle.id}`,
        formData
      );
      if (data?.error) {
        toast.error(data.message);
      } else {
        fetchVehicles();
        setModal({ modal_static: false });
        setDisplayConfirmationModal(false);
        toast.success(data.message);
      }
    } catch (error) {
     

      toast.error(error.response.data.message);
    }
  };

  const Actions = ({ vehicle }) => {
    return (
      <>
        <Link
          to="#"
          className="mr-3 text-primary"
          id="edit1"
          onClick={() => {
            getCurrentVehicle(vehicle);
            openEditModal();
          }}
        >
          <i className="mdi mdi-pencil font-size-18"></i>
        </Link>
      
         {t('Edit')}
       

        <Link
          to="#"
          className="mr-3 text-danger"
          id="delete1"
          onClick={() => {
            getCurrentVehicle(vehicle);
            showDeleteModal();
          }}
        >
          <i className="mdi mdi-trash-can font-size-18"></i>
        </Link>
        
          {t('Delete')}
        
      </>
    );
  };

  const vehicleData = {
    columns: [
      {
        label: t('make'),
        field: "make",
      },
      {
        label: t('model'),
        field: "model",
      },
      {
        label: t('service_year'),
        field: "serviceYear",
      },
      {
        label: t('number_plate'),
        field: "numberPlate",
      },
      {
        label: t('vehicle_type'),
        field: "vehicleType",
      },
      {
        label: t('vehicle_capacity'),
        field: "totalSeats",
      },
      {
        label: t('location'),
        field: "location",
      },
      {
        label: t('passenger_seats'),
        field: "passengerSeats",
      },

      {
        label: t('action'),
        field: "actions",
      },
    ],
    rows: data.map((vehicle) => {
      return {
        make: vehicle.make,
        model: vehicle.model,
        serviceYear: moment(vehicle.serviceYear).format("DD-MM-YYYY"),
        numberPlate: vehicle.numberPlate,
        vehicleType: vehicle.vehicleType,
        totalSeats: vehicle.totalSeats,

        location: vehicle.location,
        passengerSeats: vehicle.passengerSeats,

        actions: <Actions vehicle={vehicle} />,
      };
    }),
  };

  const getCurrentVehicle = (vehicle) => {
    vehicleRef.current = vehicle;
    setVehicle(vehicleRef.current);

    setDeleteMessage(
      `${t('delete_vehicle')} '${vehicleRef.current.model}'?`
    );
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title= {t('Vehicles')} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pt-3">
                  <div>
                    <Link
                      to="#"
                      onClick={() => {
                        showModal();
                      }}
                      className="btn btn-primary mb-2"
                    >
                      <i className="mdi mdi-plus mr-2"></i>  {t('Add Vehicle')}
                    </Link>
                  </div>
                  <MDBDataTable
                  noBottomColumns
                    responsive
                    data={vehicleData}
                    className="mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <VehicleModal
            getModal={setModal}
            modal={modal}
            vehicle={vehicle}
            title={t('Add Vehicle')}
            fetchVehicles={fetchVehicles}
            setAlert={setAlert}
            buttonText={t('Add')}
            controlText={'Add'}
          />

          <VehicleModal
            getModal={setEditModal}
            modal={editModal}
            vehicle={vehicle}
            title={t('Edit Vehicle')}
            fetchVehicles={fetchVehicles}
            setAlert={setAlert}
            buttonText={t('Edit')}
          />

          <DeleteConfirmation
            showModal={displayConfirmationModal}
            confirmModal={deleteVehicle}
            hideModal={hideConfirmationModal}
            message={deleteMessage}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(Vehicles);