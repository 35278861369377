import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from "react-hot-toast";
import { StateMachineProvider } from "little-state-machine";
import "./i18n";

import { AuthProvider } from './context/auth';
import { Provider } from 'react-redux';

import store from './store';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.NODE_ENV === 'production') disableReactDevTools()



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
    <StateMachineProvider>
    
      <AuthProvider>

        <BrowserRouter>
          <Toaster />

          <App />

        </BrowserRouter>
      

      </AuthProvider>
    
    </StateMachineProvider>
  </Provider>

);