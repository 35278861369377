import React from "react";

import ForgotPassword from "../../../components/Common/ForgotPassword";

function ForgottenPassword({ t }) {
 

  return (
   <React.Fragment>
    <ForgotPassword route={'admin-forgot'}buttonText={'forgot'}/>
   </React.Fragment>
   
  );
}

export default ForgottenPassword;
