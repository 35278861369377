import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import React, {useState, useEffect } from "react";
import { FadeLoader } from "react-spinners";
import { withNamespaces } from "react-i18next";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-hot-toast";
function AgencyModal({
  getModal,
  modal,

  title,
  agency,
  controlText,
  fetchAgencies,
  buttonText,
  t,
}) {
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      data: agency,
    });
  }, [agency, reset]);

  const { agencyName, email, phoneNumber } = agency;
  const [loading, setLoading] = useState(false);

  function getFileExtension(filename) {
    const extension = filename.toString().split(".").pop();

    return extension;
  }

  const upload = async (files) => {
    if (files === null) return;

    const formData = new FormData();
    if (files[0]?.size > 2048018) {
      console.log(files[0].size);

      toast.error(t("file_size"));
      return;
    }
    if (
      getFileExtension(files[0].name) === "png" ||
      getFileExtension(files[0].name) === "jpeg"
      ||
      getFileExtension(files[0].name) === "jpg"
    ) {
      formData.append("image", files[0]);
      try {
        const { data } = await axios.post("api/v1/admins/upload", formData);
        if (data?.error) {
          toast.error(data.message);
        } else {
          setValue("agencyLogo", data.url);

         

          return data.url;
        }
      } catch (err) {
        
      }
    } else {
      toast.error(t("image_err"));
      return;
    }
  };

  const addAgency = async (formData) => {
    setLoading(true);
    try {
      const { data } = await axios.post("/api/v1/admins", formData);
      if (data?.error) {
        toast.error(data.message);
      } else {
        getModal({ modal_static: false });
        setLoading(false);
        fetchAgencies();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const updateAgency = async (formData) => {
    try {
      const { data } = await axios.put(`api/v1/admins/${agency.id}`, formData);
      if (data?.error) {
        toast.error(data.message);
      } else {
        formData.id = agency.id;
        fetchAgencies();

        getModal({ modal_static: false });
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal isOpen={modal.modal_static} backdrop="static" centered size="lg">
      <ModalHeader toggle={() => getModal({ modal_static: false })}>
        {title}
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={
            controlText === "Add"
              ? handleSubmit(addAgency)
              : handleSubmit(updateAgency)
          }
        >
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="agencyName">{t("name")}</label>
                <input
                  type="text"
                  name="agencyName"
                  id="agencyName"
                  className="form-control"
                  {...register("agencyName", {
                    required: true,
                    minLength: 4,
                  })}
                  defaultValue={agencyName}
                />
              </div>
              {errors.agencyName && (
                <p style={{ color: "red" }}>{t("agency_name_err")}</p>
              )}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="email">{t("email_address")}</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  {...register("email", {
                    required: true,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  defaultValue={email}
                />
              </div>
              {errors.email && <p style={{ color: "red" }}>{t("email_err")}</p>}
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="phone">{t("phone")}</label>
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  className="form-control"
                  {...register("phoneNumber", {
                    required: true,
                    minLength: {
                      value: 9,
                    },
                    maxLength: 12,
                  })}
                  defaultValue={phoneNumber}
                />
              </div>
              {errors.phone && <p style={{ color: "red" }}>{t("phone_err")}</p>}
            </Col>
            {controlText === "Add" ? (
              <Col lg={6}>
                <div className="form-group">
                  <label htmlFor="password">{t("password")}</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    {...register("password", {
                      minLength: 11,
                    })}
                  />
                </div>
                {errors.password && (
                  <p style={{ color: "red" }}>{t("password_err")}</p>
                )}
              </Col>
            ) : null}

            <Col lg={6}>
              <div className="form-group mb-4">
                <div className="info">
                  <h6>
                    {t("agency_logo")}
                    <a
                      href="#0"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="<strong>Files accepted:<br></strong>gif,jpg,jpeg,png,pdf,doc,docx. Max file size: 150k for demo purpose."
                      className="tooltip-1"
                    >
                      <i className="icon_question_alt"></i>
                    </a>
                  </h6>
                </div>
                <div className="fileupload">
                  <input
                    type="file"
                    name="image"
                    onChange={(e) => upload(e.target.files)}
                  />

                  <input
                    type="file"
                    hidden
                    name="image"
                    {...register("agencyLogo", {})}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <ModalFooter>
            <Button
              type="button"
              color="light"
              onClick={() => getModal({ modal_static: false })}
            >
              {t("cancel")}
            </Button>
             {loading ? (<FadeLoader color="BCC015" />) : (<Button type="submit" color="primary">
              {buttonText}
            </Button>)}
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default withNamespaces()(AgencyModal);
