import React, { useState, useEffect, useRef } from "react";

import { useHistory } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Row,
  Col,
 
} from "reactstrap";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
//import "./datatables.scss";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { useStateMachine } from "little-state-machine";
import { updateAction, updateBooking, updateAgency } from "../../../utils/updateActions";
import DeleteConfirmation from "../../../components/Modals/DeleteConfirmation";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";
import AgencyModal from "../../../components/Modals/AgencyModal";

function Agency({ t }) {
  document.body.setAttribute('style', 'background: #f8f8f8!important;')
  const [loading, setLoading] = useState(true);
  const blank = {}
  const history = useHistory();
  const [breadcrumb] = useState([
    { title: t('owner'), link: "#" },
    { title: t('Agencies'), link: "#" },
  ]);
  const { actions, state } = useStateMachine({ updateAction, updateBooking, updateAgency });

  const [data, setData] = useState([]);
  const [modal, setModal] = useState({
    modal_static: false,
  });
  
  const [editModal, setEditModal] = useState({
    modal_static: false,
  });


  const [delagency] = useState({ agency: null });

  const [agency, setAgency] = useState({ agency: null });
  const agencyRef = useRef({});
  useEffect(() => { }, [delagency]);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  useEffect(() => {
    const fetchAgencies = async () => {
     
      try {
        const { data } = await axios.get("/api/v1/admins");
  
        const values = Object.values(data);
        setData(values[0]);
        setLoading(false);
  
  
      } catch (error) {
  
        toast.error(error.response.data.message)
  
      }
    };
    fetchAgencies();
  }, []);

  const fetchAgencies = async () => {
    console.log(state)
    try {
      const { data } = await axios.get("/api/v1/admins");

      const values = Object.values(data);
      setData(values[0]);
      setLoading(false);


    } catch (error) {

      toast.error(error.response.data.message)

    }
  };

  const showModal = () => {
    setModal({ modal_static: true });
   

  };

  const openEditModal = () => {
    setEditModal({ modal_static: true });
  

  };

  const showDeleteModal = () => {
    setDisplayConfirmationModal(true);
  };

  const getCurrentAgency = async (ag) => {
    setAgency(ag);
    agencyRef.current = ag;
    console.log(agencyRef.current.agencyName);
    setDeleteMessage(
      `${t('delete_message')} '${agencyRef.current.agencyName}'?`
    );
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const deleteAgency = async (formData) => {
    try {
      const { data } = await axios.delete(`/api/v1/admins/${agency.id}`, formData);
      if (data?.error) {
        toast.error(data.error);
      } else {
        fetchAgencies();
        setModal({ modal_static: false });

        setDisplayConfirmationModal(false);
        toast.success(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const agencies = {
    columns: [
      {
        label: t('agency_logo'),
        field: "agencyLogo",
      },
      {
        label: t('name'),
        field: "name",
      },
      {
        label: t('phone'),
        field: "phoneNumber",
      },
      {
        label: t('email_address'),
        field: "email",
      },
      {
        label: t('action'),
        field: "action",
      },
    ],
    rows: data.map((agency) => {
      return {
        agencyLogo: (<><img src={agency.agencyLogo} alt="" width="50" /> </>),
        name: agency.agencyName,
        phoneNumber: agency.phoneNumber,
        email: agency.email,
        action: (
          <>
            <CardBody>
              <UncontrolledDropdown className="">
                <DropdownToggle tag="i" style={{ cursor: "pointer" }} className="arrow-none">
                  <i className="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                </DropdownToggle >
                <DropdownMenu right>
                  <DropdownItem onClick={() => {
                getCurrentAgency(agency);

                openEditModal();
              }}> {t('Edit')}</DropdownItem>
                 
                  <DropdownItem onClick={() => {
                actions.updateAgency(agency);
                
                history.push('/agency-bookings')
              }}>{t('Bookings')}</DropdownItem>
                  <DropdownItem onClick={() => {
                getCurrentAgency(agency);

                showDeleteModal();
              }}>{t('Delete')}</DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>

            </CardBody>
          
          </>
        ),
      };
    }),
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs title={t('Agencies')} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div>
                    <Link
                      to="#"
                      onClick={() => {
                        showModal();
                      }}
                      className="btn btn-primary mb-2"
                    >
                      <i className="mdi mdi-plus mr-2"></i> {t('add_agency')}
                    </Link>
                  </div>
                  <MDBDataTable  noBottomColumns responsive data={agencies} className="mt-4" />
                </CardBody>
              </Card>
             
            </Col>

          </Row>
          <AgencyModal
            getModal={setModal}
            modal={modal}
            
            agency={blank}
            title={t('add_agency')}
            fetchAgencies={fetchAgencies}

            buttonText={t('Add')}
            controlText={'Add'}
          />

          <AgencyModal
            getModal={setEditModal}
            modal={editModal}
            
            agency={agency}
            title={t('edit_agency')}
            fetchAgencies={fetchAgencies}

            buttonText={t('Edit')}
            controlText={'Edit'}

          />

          <DeleteConfirmation
            showModal={displayConfirmationModal}
            confirmModal={deleteAgency}
            hideModal={hideConfirmationModal}
            message={deleteMessage}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(Agency);
