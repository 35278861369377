import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-GB";
import { FadeLoader } from "react-spinners";
import "react-datepicker/dist/react-datepicker.css";
import React, {useState, useEffect } from "react";
import {Controller, useForm } from "react-hook-form";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import { toast } from "react-hot-toast";

const key = localStorage.getItem('i18nextLng');

console.log(key);

function VehicleModal({
  modal,
  getModal,
  title,
  vehicle,
  fetchVehicles,
  buttonText,
  controlText,
  t
}) {
  const {
    register,
    
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      data: vehicle,
    });
  }, [vehicle, reset]);

  const {
    make,
    model,
    numberPlate,
    serviceYear,
    totalSeats,
    passengerSeats,
    location,
    vehicleType,
  } = vehicle;

  const [loading, setLoading] = useState(false);

  const addVehicle = async (formData) => {
    setLoading(true);
    try {
      const { data } = await axios.post("api/v1/vehicles", formData);
      if (data?.error) {
        toast.error(data.message);
      } else {
       setLoading(false);
        getModal({ modal_static: false });
        fetchVehicles();
       
      }
    } catch (error) {
     

      toast.error(error.response.data.message);
    }
  };

  const updateVehicle = async (formData) => {
    setLoading(true);
    try {
      const { data } = await axios.put(
        `api/v1/vehicles/${vehicle.id}`,
        formData
      );
      if (data?.error) {
        toast.error(data.message);
      } else {
        setLoading(false);
        fetchVehicles();
        getModal({ modal_static: false });
       
      }
    } catch (error) {
    

      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal isOpen={modal.modal_static} backdrop="static" centered size="lg">
      <ModalHeader modelggle={() => getModal({ modal_static: false })}>
        {title}
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={
            controlText === "Add"
              ? handleSubmit(addVehicle)
              : handleSubmit(updateVehicle)
          }
        >
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="make">{t('make')}</label>
                <input
                  defaultValue={make}
                  type="text"
                  name="make"
                  id="make"
                  className="form-control"
                  {...register("make", {
                    required: false,
                  })}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="model">{t('model')}</label>
                <input
                  defaultValue={model}
                  type="text"
                  name="model"
                  id="model"
                  className="form-control"
                  {...register("model", {
                    required: false,
                  })}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="numberPlate">{t('number_plate')}</label>

                <input
                  name="numberPlate"
                  defaultValue={numberPlate}
                  type="text"
                  id="numberPlate"
                  className="form-control"
                  {...register("numberPlate", {
                    required: true,
                  })}
                />
              </div>
              {errors.numberPlate && <p style={{color: 'red'}}>{t('required_field')}</p>}
            </Col>
            <Col lg={6}>
            
              {key === 'fr' ? <div className="form-group">
                <label htmlFor="serviceYear">{t('service_year')}</label>
                <Controller
                  control={control}
                  name="serviceYear"

                  render={({ field }) => (

                    <DatePicker
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      type="date"
                      locale={fr}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      defaultValue={serviceYear}
                      className="form-control"

                    />

                  )}
                />
              </div> : <div className="form-group">
                <label htmlFor="serviceYear">{t('service_year')}</label>
                <Controller
                  control={control}
                  name="serviceYear"

                  render={({ field }) => (

                    <DatePicker
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      type="date"
                      locale={en}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      defaultValue={serviceYear}
                      className="form-control"

                    />

                  )}
                />
              </div>

              }
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="totalSeats">{t('vehicle_capacity')}</label>
                <input
                  defaultValue={totalSeats}
                  type="number"
                  name="totalSeats"
                  id="totalSeats"
                  className="form-control"
                  {...register("totalSeats", {
                    required: true,
                  })}
                />
              </div>
              {errors.totalSeats && <p style={{color: 'red'}}>{t('required_field')}</p>}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="passengerSeats">{t('passenger_seats')}</label>
                <input
                  defaultValue={passengerSeats}
                  type="number"
                  name="passengerSeats"
                  id="passengerSeats"
                  className="form-control"
                  {...register("passengerSeats", {
                    required: true,
                  })}
                />
              </div>
              {errors.passengerSeats && <p style={{color: 'red'}}>{t('required_field')}</p>}
            </Col>

            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="vehicleType">{t('vehicle_type')}</label>
                <input
                  defaultValue={vehicleType}
                  type="text"
                  name="vehicleType"
                  id="vehicleType"
                  className="form-control"
                  {...register("vehicleType", {
                    required: false,
                  })}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="location">{t('location')}</label>
                <input
                  defaultValue={location}
                  type="text"
                  name="location"
                  id="location"
                  className="form-control"
                  {...register("location", {
                    required: false,
                  })}
                />
              </div>
            </Col>
          </Row>

          <ModalFooter>
            <Button
              type="button"
              color="light"
              onClick={() => getModal({ modal_static: false })}
            >
              {t('cancel')}
            </Button>
            {loading ? (<FadeLoader color="BCC015" />) : (<Button type="submit" color="primary">
              {buttonText}
            </Button>)}
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default withNamespaces() (VehicleModal);
