import React, { useState } from "react";

import {
 
  Container,
 
} from "reactstrap";

import { withNamespaces } from "react-i18next";
import moment from "moment";
import { useStateMachine } from "little-state-machine";
import { updateParcel } from "../../../utils/updateActions";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

function RegisteredParcelSummary({ t }) {
  document.body.setAttribute('style', 'background: #f8f8f8!important;')
 
  const { state } = useStateMachine(updateParcel);

  const [breadcrumb] = useState([
    { title: state?.user?.data?.data?.agencyName, link: "#" },
    { title: t('parcel_summary'), link: "#" },
  ]);

  return (
   
    <React.Fragment>
      <div className="page-content">
        <Container fluid={false}>
          <Breadcrumbs title={t('parcel_summary')} breadcrumbItems={breadcrumb} />

          <div className="row">
            <div className="col-md-9 col-lg-6 col-xl-5 mx-auto my-4 box">
              <h3>{t('parcel_summary')}</h3>

              <ul>
                <li>{t('ticket_number')}: <span> {state.parcel.parcel.data.parcelNumber} </span></li>
                <li>{t('from')}: <span> {state.parcel.parcel.data.from}</span></li>
                <li> {t('to')}: <span> {state.parcel.parcel.data.to}</span></li>
                <li>{t('sender_name')}: <span>{state.parcel.parcel.data.senderName}</span></li>
                <li>{t('sender_phone')}: <span>{state.parcel.parcel.data.senderPhone}</span></li>
                <li>{t('receiver_name')}: <span>{state.parcel.parcel.data.receiverName}</span></li>
                <li>{t('receiver_phone')}: <span>{state.parcel.parcel.data.receiverPhone}</span></li>
                <li>{t('departure_date')}: <span>{moment(
                  state.parcel.parcel.data.departureDate
                ).format("YYYY: DD: MM")}: </span></li>
                <li>{t('arrival_date')}: <span>{moment(
                  state.parcel.parcel.data.arrivalDate
                ).format("YYYY: DD: MM")}: </span></li>
                <li>{t('parcel_type')}: <span>{state.parcel.parcel.data.parcelType}</span></li>
                <li>{t('price')}: <span>{state.parcel.parcel.data.price}</span></li>
                <li>{t('vehicle_no')}: <span>{state.parcel.parcel.data.vehicleNumber}</span></li>
                <li>{t('status')}: <span>{state.parcel.parcel.data.status}</span></li>

              </ul>
              <a href="/print-ticket" className="btn-buy">{t('print_receipt')}</a>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(RegisteredParcelSummary);
