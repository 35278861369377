import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav
} from "reactstrap";
import { useStateMachine } from "little-state-machine";
import { updateAction, updateBooking, updateAgency } from "../../../utils/updateActions";
import moment from "moment";
import { MDBDataTable } from "mdbreact";
//import "./datatables.scss";
import { withNamespaces } from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";


import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";

function BookingsByAgency({ t }) {
  document.body.setAttribute('style', 'background: #f8f8f8!important;')
  const [loading, setLoading] = useState(true);
  const { state } = useStateMachine({ updateAction, updateBooking, updateAgency });

  const [breadcrumb] = useState([
    { title: t('back'), link: "/bookings-by-agency" },
    { title: t('owner'), link: "#" },
    { title: t('bookings_by_agency'), link: "#" },
  ]);

  const [data, setData] = useState([]);
 


 
  useEffect(() => {

    const fetchBookingsByAgency = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/bookings/owner-bookings/${state.agency.id}`
        );
  
        const values = Object.values(data);
        setData(values[0]);
  
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
  
      }
    };
    fetchBookingsByAgency();
  }, [state.agency.id]);



  const tripData = {
    columns: [

      {
        label: t('name'),
        field: "name",
      },
      {
        label: t('id_card'),
        field: "idcard",
      },
      {
        label: t('phone'),
        field: "phone",
      },

      {
        label: t('from'),
        field: "from",
      },
      {
        label: t('to'),
        field: "to",
      },
      {
        label: t('departure_time'),
        field: "departure",
      },

      {
        label: t('journey_date'),
        field: "journeyDate",
      },
      {
        label: t('seat_no'),
        field: "seat",
      },

      {
        label: t('fare'),
        field: "price",
      },


    ],
    rows: data.map((trip) => {
      return {
        name: trip.name,
        idcard: trip.idCardNo,
        phone: trip.phoneNumber,
        from: trip.departureCity,
        to: trip.arrivalCity,
        departure: trip.departureTime,

        journeyDate: moment(trip.journeyDate).format("YYYY:MM:DD"),
        seat: trip.seat,

        price: trip.price,



      };
    }),
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={`${state.agency.agencyName}`} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pt-0">
                  <Nav tabs className="nav-tabs-custom mb-4"></Nav>
                  <MDBDataTable exportToCSV entrieslabel searchLabel={t('Search')} noRecordsFoundLabel={t('no_records')} responsive data={tripData} className="mt-4" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(BookingsByAgency);
