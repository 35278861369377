import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
 
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
//import { SpinnerCircular } from "spinners-react";
import { MDBDataTable } from "mdbreact";
//import "./datatables.scss";
import { useStateMachine } from "little-state-machine";
import { updateAction, updateBooking } from "../../../utils/updateActions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import BookingModal from "../../../components/Modals/BookingModal";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";
import { withNamespaces } from "react-i18next";
import io from 'socket.io-client';

const ENDPOINT = "http://localhost:5000/";


function BookTrip({t}) {
 
 
  const [loading, setLoading] = useState(true);
 

  

  const { actions, state } = useStateMachine({ updateAction, updateBooking });

  const history = useHistory();
  const [breadcrumb] = useState([
    { title: state?.user?.data?.data?.agencyName, link: "#" },
    { title: t('book_tickets'), link: "#" },
  ]);

  const [data, setData] = useState([]);
  const tripRef = useRef({});
  

  const [tab, setTab] = useState({ activeTabJustify: "1" });
  const [modal, setModal] = useState({
    modal_static: false,
  });
  

  const showModal = () => {
   
    setModal({ modal_static: true });
   
  };

 
  function toggleCustomJustified(currentTab) {
    if (tab.activeTabJustify !== currentTab) {
      setTab({
        activeTabJustify: currentTab,
      });
    }
  }

  useEffect(() => {
    const socket = io(ENDPOINT);
    socket.on("trip:update", data => {
     fetchTrips();
    });

   
   

    // CLEAN UP THE EFFECT
    return () => socket.disconnect();
  

   
  }, [])

  const fetchTrips = async () => {
    try {
      const { data } = await axios.get("api/v1/trips");

      const values = Object.values(data);
      setData(values[0]);

     
    } catch (err) {
     
     
    }
  };

  
 

 
 
  document.body.setAttribute('style', 'background: #f8f8f8!important;')
 

  useEffect(() => {
   
    const getTrips = async () => {
      try {
        const { data } = await axios.get("api/v1/trips");
  
        const values = Object.values(data);
        setData(values[0]);
  
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
      
       
      }
    };
  
    getTrips();
  }, [t]);

 
 
  const filterUpcoming = data.filter((trip) => {
    return (
     trip.journeyDate >
      moment(new Date()).format("MM-DD-YYYY")
    );
  });

  const filterDay = data.filter((trip) => {
    return (
      trip.journeyDate ===
      moment(new Date()).format("MM-DD-YYYY")
    );
  });

  const filterPast = data.filter((trip) => {
    return (
     trip.journeyDate <
      moment(new Date()).format("MM-DD-YYYY")
    );
  });

  const Actions = ({ trip }) => {
    return trip.status === "Past date" || trip.status === "Fully booked" ? (
      <>
        <Link
          to="#"
          className="mr-3 text-primary"
          id="view1"
          onClick={() => {
            getCurrentTrip(trip);
            history.push("/list-trip-bookings");
          }}
        >
          <i className="mdi mdi-view-list font-size-18"></i>
        </Link>
       
      </>
    ) : (
      <>
        <Link
          to="#"
          className="mr-3 text-primary"
          id="buy1"
          onClick={() => {
            getCurrentTrip(trip);
            showModal();
          }}
        >
          <i className="mdi mdi-cart font-size-18"></i>
        </Link>
       

        <Link
          to="#"
          className="mr-3 text-primary"
          id="view1"
          onClick={() => {
            getCurrentTrip(trip);
            history.push("/list-trip-bookings");
          }}
        >
          <i className="mdi mdi-view-list font-size-18"></i>
        </Link>
       
      </>
    );
  };

  

  const tableColumns = [
    {
      label: t("from"),
      field: "from",
    },
    {
      label: t("to"),
      field: "to",
    },
    {
      label: t("departure"),
      field: "departure",
    },
    {
      label: t("arrival"),
      field: "arrival",
    },
    {
      label: t("journey_date"),
      field: "journeyDate",
    },
    {
      label: t("seats"),
      field: "seats",
    },
    {
      label: t("status"),
      field: "status",
    },
    {
      label: "Type",
      field: "tripType",
    },
    /*{
      label: t("vehicle_no"),
      field: "vehicleNo",
    },*/
    {
      label: t("tickets_reserved"),
      field: "reserved",
    },
    {
      label: t("available"),
      field: "available",
    },
    {
      label: t("action"),
      field: "actions",
    },
  ];


  const tripData = {
    columns: tableColumns,
    rows: data.map((trip) => {
      return {
        from: trip.from,
        to: trip.to,
        departure: trip.departure,
        arrival: trip.arrival,
        journeyDate: moment(trip.journeyDate).format("DD-MM-YYYY"),
        seats: trip.seats,
        status:
        trip.status === "Fully booked"  || trip.status === 'Plein' ?  (
          <div className="badge badge-soft-warning font-size-12">
            {t("fully_booked")}
          </div>
        ) : 
          moment(new Date()).format("MM-DD-YYYY") >
           trip.journeyDate ? (
          <div className="badge badge-soft-danger font-size-12">
            {t("past")}
          </div>
        ) : (
          <div className="badge-soft-success font-size-12">
            
            {t("yet_to_start")}
          </div>
          ),
        //vehicleNo: trip.vehicleNo,
        tripType: trip.tripType,
        reserved: trip.reserved,
         available:  trip.seats - trip.reserved,
        actions: <Actions trip={trip} />,
      };
    }),
  };

  const tripFiltered1 = {
    columns: tableColumns,
    rows: filterDay.map((trip) => {
      return {
        from: trip.from,
        to: trip.to,
        departure: trip.departure,
        arrival: trip.arrival,
        journeyDate: moment(trip.journeyDate).format("MM-DD-YYYY"),
        seats: trip.seats,
        status:
        trip.status === "Fully booked"  || trip.status === 'Plein' ? (
          <div className="badge badge-soft-warning font-size-12">
            {t("fully_booked")}
          </div>
        ) :
          moment(new Date()).format("MM-DD-YYYY") >
           trip.journeyDate ? (
          <div className="badge badge-soft-danger font-size-12">
            {t("past")}
          </div>
        ) : (
          <div className="badge-soft-success font-size-12">
            
            {t("yet_to_start")}
          </div>
          ),
        tripType: trip.tripType,
        reserved: trip.reserved,
        available: trip.seats - trip.reserved,

        actions: <Actions trip={trip} />,
      };
    }),
  };

  const tripFiltered2 = {
    columns: tableColumns,
    rows: filterUpcoming.map((trip) => {
      return {
        from: trip.from,
        to: trip.to,
        departure:trip.departure,
        arrival: trip.arrival,
        journeyDate: moment(trip.journeyDate).format("MM-DD-YYYY"),
        seats: trip.seats,
        status:
        trip.status === "Fully booked" || trip.status === 'Plein' ? (
          <div className="badge badge-soft-warning font-size-12">
            {t("fully_booked")}
          </div>
        ) :
          moment(new Date()).format("MM-DD-YYYY") >
          trip.journeyDate ? (
          <div className="badge badge-soft-danger font-size-12">
            {t("past")}
          </div>
        ) : (
          <div className="badge-soft-success font-size-12">
          
            {t("yet_to_start")}
          </div>
          ),
          tripType: trip.tripType,
       // vehicleNo: trip.vehicleNo,
        reserved: trip.reserved,
        available: trip.seats - trip.reserved,
        actions: <Actions trip={trip} />,
      };
    }),
  };

  const tripFiltered3 = {
    columns: tableColumns,
    rows: filterPast.map((trip) => {
      return {
        from: trip.from,
        to: trip.to,
        departure:trip.departure,
        arrival: trip.arrival,
        journeyDate: moment(trip.journeyDate).format("MM-DD-YYYY"),
        seats: trip.seats,
        status:
        trip.status === "Fully booked"  || trip.status === 'Plein' ? (
          <div className="badge badge-soft-warning font-size-12">
            {t("fully_booked")}
          </div>
        ) : 
          moment(new Date()).format("MM-DD-YYYY") >
           trip.journeyDate ? (
          <div className="badge badge-soft-danger font-size-12">
            {t("past")}
          </div>
        ) : (
          <div className="badge-soft-success font-size-12">
            
            {t("yet_to_start")}
          </div>
          ),
          tripType: trip.tripType,
        //vehicleNo: trip.vehicleNo,
        reserved: trip.reserved,
        available: trip.seats-trip.reserved,
        actions: <Actions trip={trip} />,
      };
    }),
  };

  const getCurrentTrip = (trip) => {
    tripRef.current = trip;
    actions.updateAction({ trip: tripRef.current });
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={t('Bookings')} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pt-0">
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: tab.activeTabJustify === "1",
                        })}
                        onClick={() => {
                          toggleCustomJustified("1");
                        }}
                      >
                        <span className="d-none d-sm-block">{t('all_trips')}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: tab.activeTabJustify === "2",
                        })}
                        onClick={() => {
                          toggleCustomJustified("2");
                        }}
                      >
                        <span className="d-none d-sm-block">{t('trips_today')}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: tab.activeTabJustify === "3",
                        })}
                        onClick={() => {
                          toggleCustomJustified("3");
                        }}
                      >
                        <span className="d-none d-sm-block">{t('upcoming')}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: tab.activeTabJustify === "4",
                        })}
                        onClick={() => {
                          toggleCustomJustified("4");
                        }}
                      >
                        <span className="d-none d-sm-block">{t('past')}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={tab.activeTabJustify}>
                    <TabPane tabId="1" className="p-3">
                      <MDBDataTable
                       noBottomColumns
                        responsive
                        data={tripData}
                        className="mt-4"
                      />
                    </TabPane>
                    <TabPane tabId="2" className="p-3">
                      <MDBDataTable
                        responsive
                        data={tripFiltered1}
                        className="mt-4"
                      />
                    </TabPane>
                    <TabPane tabId="3" className="p-3">
                      <MDBDataTable
                        responsive
                        data={tripFiltered2}
                        className="mt-4"
                      />
                    </TabPane>
                    <TabPane tabId="4" className="p-3">
                      <MDBDataTable
                      noBottomColumns
                        responsive
                        data={tripFiltered3}
                        className="mt-4"
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        <BookingModal
            getModal={setModal}
            modal={modal}
           
            title={t("sell_ticket")}
            fetchTrips={fetchTrips}
           
            buttonText={t("submit")}
            controlText={'Add'}
                      />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(BookTrip);
