import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { updateAction, updateBooking, updateAgency } from "../../../utils/updateActions";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
//import "./datatables.scss";
import { withNamespaces } from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";


import { toast } from "react-hot-toast";

function AgencyBookings({t}) {
  document.body.setAttribute('style', 'background: #f8f8f8!important;')
  const history = useHistory();
  const [breadcrumb] = useState([
    { title: t('owner'), link: "#" },
    { title: t('Agencies'), link: "#" },
  ]);
  const { actions } = useStateMachine({ updateAction, updateBooking, updateAgency });
  const [data, setData] = useState([]);

  
  useEffect(() => {
    fetchAgencies();
  }, []);

  const fetchAgencies = async (req, res) => {
    try {
      const { data } = await axios.get("/api/v1/admins");

      const values = Object.values(data);
      setData(values[0]);

     
    } catch (error) {
      
     toast.error(error.response.data.message)
      
    }
  };

  const agencies = {
    columns: [
      {
        label: t('name'),
        field: "name",
      },
      {
        label: t('phone'),
        field: "phone",
      },
      {
        label: t('email_address'),
        field: "email",
      },
      {
        label: t('action'),
        field: "action",
      },
    ],
    rows: data.map((agency) => {
      return {
        name: agency.agencyName,
        phone: agency.phone,
        email: agency.email,
        action: (
          <td>
            <Link
              to="#"
              className="mr-3 text-primary"
              id={"edit" + agency.id}
              onClick={() => {
                actions.updateAgency(agency)
                
                history.push('/agency-bookings')
              }}
            >
              <i className="mdi mdi-view-list font-size-18"></i>
            </Link>
            <UncontrolledTooltip target={"edit" + agency.id} placement="top">
              {t('Bookings')}
            </UncontrolledTooltip>

          </td>
        ),
      };
    }),
  };

  return (
    <React.Fragment>
      <div className="page-content">
           <Container>
           <Breadcrumbs title="Agencies" breadcrumbItems={breadcrumb} />
           <Row>
           
            <Col lg={12}>
              <Card>
                <CardBody>

                  <MDBDataTable responsive data={agencies} className="mt-4" />
                </CardBody>
              </Card>
              </Col>
              </Row>
              </Container>
              </div>
            
    </React.Fragment>
  );
}

export default withNamespaces()(AgencyBookings);
