import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useStateMachine } from "little-state-machine";
import { updateUser } from "../../../../utils/updateActions";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import Barchart from "./Barchart";

import { withNamespaces } from "react-i18next";

function TellerDashboard({ t }) {
  const { state } = useStateMachine(updateUser);

  const [breadcrumbItems] = useState(
    [
      { title: state?.user?.data?.data?.agencyName, link: "#" },
      { title: t("ticket_chart"), link: "#" },
    ],
  )




  document.body.setAttribute('style', 'background: #f8f8f8!important;')
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs
            title={t("ticket_chart")}
            breadcrumbItems={breadcrumbItems}
          />
          <Card>
            <CardBody>
              <Row>
                <Col xl={12}>
                  <Barchart />
                </Col>
              </Row>
            </CardBody>
          </Card>

        </Container>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(TellerDashboard);
