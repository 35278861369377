import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useStateMachine } from "little-state-machine";
import {  updateBooking, updateAction } from "../../utils/updateActions";

import { useForm } from "react-hook-form";
import axios from "axios";


function BookingModal({
  modal,
  getModal,
  title,
  booking,
 
  fetchTrips,
  t,
  buttonText,
  controlText,
}) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
 
  const history = useHistory();
  
  const { actions, state } = useStateMachine({ updateBooking, updateAction });
  
 
  useEffect(() => {
    reset({
      data: booking,
    });
  }, [booking, reset]);
  
  const addBooking = async (formData) => {
    
    try {
      const { data } = await axios.post(`api/v1/bookings/${state.trip.trip.id}`, formData);
      
      if (data?.error) {
        toast.error(data.message);
      } else {
       
        actions.updateBooking({ booking: data });
        

        history.push("/booking-summary");
        getModal({ modal_static: false });
       
        toast.success(data.message);
      }
    } catch (error) {

      toast.error(error.response.data.message);
     
    }
  };

  const update = async (formData) => {
    
    try {
      const { data } = await axios.put(`/Bookings/${booking.id}`, formData);
      if (data?.error) {
        toast.error(data.message);
      } else {
        fetchTrips();
        getModal({ modal_static: false });
        toast.success(data.message);
      }
    } catch (error) {


      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal isOpen={modal.modal_static} backdrop="static" centered size="lg">
      <ModalHeader toggle={() => getModal({ modal_static: false })}>
        {t("sell_ticket")}
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={
            controlText === "Add"
              ? handleSubmit(addBooking)
              : handleSubmit(update)
          }
        >
          <Row>
            {controlText === 'Add' ? (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="name">{t('name')}</label>
                <input

                  type="text"
                  name="name"
                  id="name"

                  className="form-control"
                  {...register("name", {
                    required: true,
                    minLength: 2
                  })}
                />
              </div>
              {errors.name && <p style={{ color: 'red' }}>{t('agency_branch_err')}</p>}
            </Col>) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="name">{t('name')}</label>
                <input

                  type="text"
                  name="name"
                  id="name"
                  defaultValue={state?.booking?.booking?.name}

                  className="form-control"
                  {...register("name", {
                    required: true,
                    minLength: 2
                  })}
                />
              </div>
              {errors.name && <p style={{ color: 'red' }}>{t('agency_branch_err')}</p>}
            </Col>)}
            {controlText === 'Add' ? (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="idCardNo">{t('id_card')}</label>
                <input

                  type="number"
                  name="idCardNo"
                  id="idCardNo"
                 
                  className="form-control"
                  {...register("idCardNo", {
                    required: true,
                    
                  })}
                />
              </div>
              {errors.idCardNo && <p style={{ color: 'red' }}>{t('id_card_err')}</p>}
            </Col>) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="idCardNo">{t('id_card')}</label>
                <input

                  type="number"
                  name="idCardNo"
                  id="idCardNo"
                  defaultValue={state?.booking?.booking?.idCardNo}
                  className="form-control"
                  {...register("idCardNo", {
                    required: true,
                    
                  })}
                />
              </div>
              {errors.idCardNo && <p style={{ color: 'red' }}>{t('id_card_err')}</p>}
            </Col>)}
           {/* <Col lg={6}>
              <div className="form-group">
                <label htmlFor="number_of_seats">{t('number_of_seats')}</label>
                <input
                  defaultValue={""}
                  type="number"
                  name="number_of_seats"
                  id="number_of_seats"
                  className="form-control"
                  {...register("numberOfSeats", {
                    required: true,

                  })}
                />
              </div>
              {errors.idCardNo && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>*/}

            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="phoneNumber">{t('phone')}</label>
                <input
                 
                  type="number"
                  name="phoneNumber"
                  id="phoneNumber"
                  className="form-control"
                  {...register("phoneNumber", {
                    required: true,
                    minLength: 9,
                    maxLength: 12
                  })}
                />
              </div>
              {errors.phoneNumber && <p style={{ color: 'red' }}>{t('phone_err')}</p>}
            </Col>
           {/* <Col lg={6}>
              <div className="form-group">
                <label htmlFor="ticketType">{t('ticket_type')}</label>
                <select
                  className="form-control"
                  defaultValue={""}
                  type="text"
                  name="ticketType"
                  {...register("ticketType", {
                    required: true,
                  })}
                >
                  <option>{t('one_way')}</option>
                  <option>{t('return')}</option>
                  <option>{t('open_ticket')}</option>
                </select>
              </div>
              {errors.ticketType && <p style={{ color: 'red' }}>{t('ticket_type')}</p>}
            </Col>*/}
          </Row>

          <ModalFooter>
            <Button
             
              color="light"
              onClick={() => getModal({ modal_static: false })}
            >
              {t('cancel')}
            </Button>
            <Button type="submit" color="primary">
              {buttonText}
            </Button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default withNamespaces()(BookingModal);
