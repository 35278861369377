import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import { MDBDataTable } from "mdbreact";
//import "./datatables.scss";
import { useStateMachine } from "little-state-machine";
import { updateParcel } from "../../../utils/updateActions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";

import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";
import { withNamespaces } from "react-i18next";
import ParcelModal from "../../../components/Modals/ParcelModal";

function Parcels({ t }) {
  document.body.setAttribute('style', 'background: #f8f8f8!important;')
 
  const [loading, setLoading] = useState(true);


  const [editModal, setEditModal] = useState({
    modal_static: false,
  });
  const parcelRef = useRef({});

  const { actions, state } = useStateMachine({ updateParcel });


  const [breadcrumb] = useState([
    { title: state?.user?.data?.data?.agencyName, link: "#" },
    { title: t('parcels'), link: "#" },
  ]);

  const [data, setData] = useState([]);


  const [tab, setTab] = useState({ activeTabJustify: "1" });


  const showEditModal = () => {
    setEditModal({ modal_static: true });

  };

  function toggleCustomJustified(currentTab) {
    if (tab.activeTabJustify !== currentTab) {
      setTab({
        activeTabJustify: currentTab,
      });
    }
  }

  useEffect(() => {
    const fetchParcels = async () => {
      try {
        const { data } = await axios.get(`api/v1/parcels/to-location`);

        const values = Object.values(data);
        setData(values[0]);

        setLoading(false);
        toast.success(data.message);
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);

      }
    };
    fetchParcels();
  }, [state.user.data.data.agencyId, t]);

  const getParcels = async () => {
    try {
      const { data } = await axios.get(`api/v1/parcels/to-location`);

      const values = Object.values(data);
      setData(values[0]);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(t('failed'));

    }
  };



  const filterYetToBeCollected = data.filter((parcel) => {


    return (
      parcel.status === 'Received' || parcel.status === 'Reçu'

    );

  });

  const filterCollected = data.filter((parcel) => {
    return (
      parcel.status === 'Collecté' || parcel.status === 'Collected'

    );
  });

  const Actions = ({ parcel }) => {
    return (

      <>
        <Link
          to="#"
          className="mr-3 text-primary"
          id="edit1"
          onClick={() => {
            getCurrentparcel(parcel);

            showEditModal();
          }}
        >
          <i className="mdi mdi-pencil font-size-18"></i>
        </Link>
        


      </>
    );
  };


  const tableColumns = [
    {
      label: t("ticket_number"),
      field: "parcel",
    },

    {
      label: t("from"),
      field: "from",
    },
    /*{
      label: t("departure_date"),
      field: "departureDate",
    },*/
   /* {
      label: t("arrival_date"),
      field: "arrivalDate",
    },*/
    {
      label: t("sender_name"),
      field: "senderName",
    },
    {
      label: t("sender_phone"),
      field: "senderPhone",
    },
    {
      label: t("receiver_name"),
      field: "receiverName",
    },
    {
      label: t("receiver_phone"),
      field: "receiverPhone",
    },
    {
      label: t("received_date"),
      field: "receivedDate",
    },

    {
      label: t("parcel_type"),
      field: "parcelType",
    },

    {
      label: t("price"),
      field: "price",
    },
   /* {
      label: t("vehicle_no"),
      field: "vehicleNumber",
    },*/
    {
      label: t("status"),
      field: "status",
    },


    {
      label: t("action"),
      field: "actions",
    },
  ];

  const collectedColumns = [
    {
      label: t("ticket_number"),
      field: "parcel",
    },

    {
      label: t("from"),
      field: "from",
    },
    {
      label: t("received_date"),
      field: "receivedDate",
    },
    
    {
      label: t("sender_name"),
      field: "senderName",
    },
    {
      label: t("sender_phone"),
      field: "senderPhone",
    },
    {
      label: t("receiver_name"),
      field: "receiverName",
    },
    {
      label: t("receiver_phone"),
      field: "receiverPhone",
    },

    {
      label: t("parcel_type"),
      field: "parcelType",
    },

    {
      label: t("price"),
      field: "price",
    },
   
    {
      label: t("status"),
      field: "status",
    },



  ];

  const parcelData = {
    columns: collectedColumns,
    rows: filterCollected.map((parcel) => {
      return {
        parcel: parcel.parcelNumber,
        from: parcel.from,
        receivedDate: moment(parcel.receivedDate).format('DD-MM-YYYY'),
        //arrivalDate: moment(parcel.arrivalDate).format('DD-MM-YYYY'),
        senderName: parcel.senderName,
        senderPhone: parcel.senderPhone,
        receiverName: parcel.receiverName,
        receiverPhone: parcel.receiverPhone,
        //vehicleNumber: parcel.vehicleNumber,
        price: parcel.price,
        parcelType: parcel.parcelType,

        status:
          parcel.status === "Collecté" || parcel.status === 'Collected' ? (
            <div className="badge badge-soft-warning font-size-12">
              {t("collected")}
            </div>
          ) : (
            <div className="badge badge-soft-success font-size-12">
              {t("received")}
            </div>
          ),

      };
    }),
  };



  const parcelFiltered2 = {
    columns: tableColumns,
    rows: filterYetToBeCollected.map((parcel) => {
      return {
        parcel: parcel.parcelNumber,
        from: parcel.from,
        receivedDate: moment(parcel.receivedDate).format('DD-MM-YYYY'),
       // arrivalDate: moment(parcel.arrivalDate).format('DD-MM-YYYY'),
        senderName: parcel.senderName,
        senderPhone: parcel.senderPhone,
        receiverName: parcel.receiverName,
        receiverPhone: parcel.receiverPhone,
        //vehicleNumber: parcel.vehicleNumber,
        price: parcel.price,
        parcelType: parcel.parcelType,

        status:
          parcel.status === "Collected" ? (
            <div className="badge badge-soft-warning font-size-12">
              {t("collected")}
            </div>
          ) : (
            <div className="badge badge-soft-success font-size-12">
              {t("sent")}
            </div>
          ),
        actions: <Actions parcel={parcel} />,
      };
    }),
  };



  const getCurrentparcel = (parcel) => {
    parcelRef.current = parcel;
    actions.updateParcel({ parcel: parcelRef.current });
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={t('Parcels')} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pt-0">
                  <Nav tabs className="nav-tabs-custom nav-start">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: tab.activeTabJustify === "1",
                        })}
                        onClick={() => {
                          toggleCustomJustified("1");
                        }}
                      >
                        <span className="d-none d-sm-block">{t('available')}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: tab.activeTabJustify === "2",
                        })}
                        onClick={() => {
                          toggleCustomJustified("2");
                        }}
                      >
                        <span className="d-none d-sm-block">{t('discharged')}</span>
                      </NavLink>
                    </NavItem>

                  </Nav>

                  <TabContent activeTab={tab.activeTabJustify}>
                    <TabPane tabId="1" className="p-3">
                      <MDBDataTable
                       noBottomColumns
                        responsive
                        data={parcelFiltered2}
                        className="mt-4"
                      />
                    </TabPane>

                    <TabPane tabId="2" className="p-3">
                      <MDBDataTable
                        responsive
                        data={parcelData}
                        className="mt-4"
                      />
                    </TabPane>

                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ParcelModal
            getModal={setEditModal}
            modal={editModal}
            parcel={parcelRef.current}
            title={t("edit_parcel")}
            fetchParcels={getParcels}

            buttonText={t("Edit")}
            controlText={'editStatus'}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(Parcels);
