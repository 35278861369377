import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import bg from "../../assets/images/maintenance-bg.png";

class ResetHelper extends Component {
    constructor(props) {
        super(props);
        this.state={}
        
    }
    render() {
        return (
            <React.Fragment>
        

        <div className="my-5 pt-sm-5">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="text-center">

                            <Row className="justify-content-center">
                                <Col sm={4}>
                                    <div className="maintenance-img">
                                        <img src={bg} alt="" className="img-fluid mx-auto d-block"/>
                                    </div>
                                </Col>
                            </Row>
                            
                            <p>Please check your email for your password reset link .</p>

                            <Row>
                                
                                
                               
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

            </React.Fragment>
        );
    }
}

export default ResetHelper;