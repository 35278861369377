import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/AdminLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

//css
import './assets/css/style.css';
import './assets/css/custom.css';


import './assets/css/userstyle.css';


// Import scss
import "./theme.scss";


class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.getLayout = this.getLayout.bind(this);
    
	}
 
 	/**
	 * Returns the layout
	 */
	getLayout = () => {
		let layoutCls = VerticalLayout;
		return layoutCls;
	};


	render() {
		const Layout = this.getLayout();
   
		return (
			<React.Fragment>
				<Router>
					<Switch>
        
						{publicRoutes.map((route, idx) => (
							<AppRoute
              exact
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}

						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
               exact
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))}
          
					</Switch>
				</Router>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};


export default connect(mapStateToProps, null)(App);
