import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { Container } from "reactstrap";
import { groupBy, intersection } from "lodash";
import { withNamespaces } from "react-i18next";
import ScaleLoader from "react-spinners/ScaleLoader";

const ParcelChart = ({ t }) => {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData]= useState([]);

  useEffect(() => {
    const setChart = async () => {
      try {
        const { data } = await axios.get("api/v1/parcels/all-parcels");
        
        const values = Object.values(data);
        setData(values[0]);
       
        if (data !== null) {
          const mapByAgency = (booking) => booking.agencyName;
          const agencyBookings = groupBy(values[0], mapByAgency);
  
          const book = Object.values(agencyBookings).map(
            (booking) => booking.length
          );
  
          const agency_labels = Object.keys(agencyBookings);
  
          const COLORS = [
            "#588B8B",
            "#FFD5C2",
            "#F28F3B",
            "#C8553D",
            "#BEC5AD",
            "#A4B494",
            "#519872",
            "#3B5249",
            "#34252F",
            "#D6EFFF",
            "#FED18C",
            "#FE654F",
            "#AFC2D5",
          ];
  
          let labelColors = {};
  
          const mapColorsToLabels = (labels) => {
            const usedKeys = intersection(Object.keys(labelColors), labels);
            let firstAvailColor = usedKeys.length;
  
            let chartColors = [];
            let usedColors = {};
  
            usedKeys.forEach((label) => {
              usedColors[labelColors[label]] = true;
            });
  
            labels.forEach((label) => {
              if (!labelColors[label]) {
                while (usedColors[COLORS[firstAvailColor]]) {
                  firstAvailColor += 1;
                }
  
                labelColors[label] = COLORS[firstAvailColor];
                firstAvailColor += 1;
              }
  
              chartColors.push(labelColors[label]);
            });
  
            return chartColors;
          };
  
          setChartData({
            labels: agency_labels,
            datasets: [
              {
                label: t('parcels'),
                data: book,
                backgroundColor: mapColorsToLabels(agency_labels),
  
                borderWidth: 1,
              },
            ],
          });
        }
  
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
  
    setChart();
  }, [t]);

 

  return data.length === 0? (<React.Fragment>
    <div className="page-content">
      <Container className="">
        <div className="loader-outer-bar">
          <div className="loader-inner">
           <p>{t('no_parcel')}.</p>
          </div>
        </div>
      </Container>
    </div>
  </React.Fragment>):(
  loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container className="">
          <div className="loader-outer-bar">
            <div className="loader-inner">
            
             <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <div className="">
      <Bar
        data={chartData}
        options={{
          responsive: true,
          title: { text: t('parcels_by_agency'), display: true },
          legend: {
            display: true,
            position: "bottom",
          },
        }}
      />
    </div>
  ));
};
export default withNamespaces()(ParcelChart);
