import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { FadeLoader } from "react-spinners";
import React, {useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import { toast } from "react-hot-toast";

function LocationModal({
  modal,
  getModal,
  title,
  location,
  fetchLocations,
  
  buttonText,
  controlText,
  t
}) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      data: location,
    });
  }, [location, reset]);
  const [loading, setLoading]= useState(false);
  const { city, neighbourhood, agencyBranch, country, locationType } = location;

  const addLocation = async (formData) => {
    setLoading(true);
    try {
      const { data } = await axios.post("api/v1/locations", formData);
      if (data?.error) {
        toast.error(data.message);
      } else {
        setLoading(false);
        getModal({ modal_static: false });
        fetchLocations();
      
      }
    } catch (error) {

      toast.error(error.response.data.message);
    }
  };

  const updateLocation = async (formData) => {
    try {
      const { data } = await axios.put(
        `api/v1/locations/${location.id}`,
        formData
      );
      if (data?.error) {
        toast.error(data.message);
      } else {
        fetchLocations();
        getModal({ modal_static: false });
      
      }
    } catch (error) {


      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal isOpen={modal.modal_static} backdrop="static" centered size="lg">
      <ModalHeader neighbourhoodggle={() => getModal({ modal_static: false })}>
        {title}
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={
            controlText === "Add"
              ? handleSubmit(addLocation)
              : handleSubmit(updateLocation)
          }
        >
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="city">{t('city')}</label>
                <input
                  defaultValue={city}
                  type="text"
                  name="city"
                  id="city"
                  className="form-control"
                  {...register("city", {
                    required: true,
                    minLength: 4
                  })}
                />
              </div>
              {errors.city && <p style={{ color: 'red' }}>{t('city_err')}</p>}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="neighbourhood">{t('neighbourhood')}</label>
                <input
                  defaultValue={neighbourhood}
                  type="text"
                  name="neighbourhood"
                  id="neighbourhood"
                  className="form-control"
                  {...register("neighbourhood", {
                    required: true,
                    //minLength: 4
                  })}
                />
              </div>
              {errors.neighbourhood && <p style={{ color: 'red' }}>{t('city_err')}</p>}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="agencyBranch">{t('agency_branch')}</label>

                <input
                  name="agencyBranch"
                  defaultValue={agencyBranch}
                  type="text"
                  id="agencyBranch"
                  className="form-control"
                  {...register("agencyBranch", {
                    required: true,
                  })}
                />
              </div>
              {errors.agencyBranch && <p style={{ color: 'red' }}>{t('agency_branch_err')}</p>}
            </Col>

            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="locationType">{t('location_type')}</label>
                <input
                  defaultValue={locationType}
                  type="text"
                  name="locationType"
                  id="locationType"
                  className="form-control"
                  {...register("locationType", {
                    required: true,
      
                  })}
                />
              </div>
              {errors.locationType && <p style={{ color: 'red' }}>{t('city_err')}</p>}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="country">{t('country')}


                </label>
                <input
                  defaultValue={country}
                  type="text"
                  name="country"
                  id="country"
                  className="form-control"
                  {...register("country", {
                    required: true,
                    minLength: 4
                  })}
                />
              </div>
              {errors.country && <p style={{ color: 'red' }}>{t('city_err')}</p>}
            </Col>
          </Row>

          <ModalFooter>
            <Button
              type="button"
              color="light"
              onClick={() => getModal({ modal_static: false })}
            >
              {t('cancel')}
            </Button>
            {loading ? (<FadeLoader color="BCC015" />) : (<Button type="submit" color="primary">
              {buttonText}
            </Button>)}
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default withNamespaces() (LocationModal);
