import React, { useState, useEffect, useCallback } from "react";
import { Bar } from "react-chartjs-2";
import { Container } from "reactstrap";
import axios from "axios";
//import { groupBy } from "lodash";
import ScaleLoader from "react-spinners/ScaleLoader";

import { withNamespaces } from "react-i18next";

const ParcelsChart = ({ t }) => {
  const [loading, setLoading] = useState(true);

  const [chartData, setChartData] = useState({});
  const [data, setData]= useState([]);

  const fetchParcelsByAgency = useCallback  (async() => {
    try {
      const { data } = await axios.get(`/api/v1/parcels`);

      const values = Object.values(data);
      setData(values[0]);
      if (data !== null) {
       // const mapByMonth = (parcel) => setMonth(parcel.departureDate);
        /*function setMonth(date) {
          const currentYear = new Date().getFullYear();
          const d = new Date(date);
          if (d.getFullYear() !== currentYear) {
            return;
          }

          return d.toLocaleString("default", { month: "short" });
        }*/
        //const parcelsPerMonth = groupBy(values[0], mapByMonth);

        let jan = [];
        let feb = [];
        let mar = [];
        let apr = [];
        let may = [];
        let jun = [];
        let jul = [];
        let aug = [];
        let sept = [];
        let oct = [];
        let nov = [];
        let dec = [];
        for (const element of Object.entries(values)) {
          //key = element[0]
          let value = element[1];

          for (let i = 0; i < value.length; i++) {
            if (value[i].month === "Jan") {
              jan.push(value[i].price);

            }


            if (value[i].momth === "Feb") {
              feb.push(value[i].price);
            }
            if (value[i].month === "Mar") {
              mar.push(value[i].price);
            }

            if (value[i].month === "Apr") {
              apr.push(value[i].price);
            }

            if (value[i].month === "May") {
              may.push(value[i].price);
            }

            if (value[i].month === "Jun") {
              jun.push(value[i].price);
            }

            if (value[i].month === "Jul") {
              jul.push(value[i].price);
            }

            if (value[i].month === "Aug") {
              aug.push(value[i].price);
            }

            if (value[i].month === "Sep") {
              sept.push(value[i].price);
            }

            if (value[i].month === "Oct") {
              oct.push(value[i].price);
            }

            if (value[i].month === "Nov") {
              nov.push(value[i].price);
            }

            if (value[i].month === "Dec") {
              dec.push(value[i].price);
            }
          }
        }

        let sales = [];
        if (jan.length !== 0) {
          let jan_sales = jan.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(jan_sales);
        }

        if (feb.length !== 0) {
          let feb_sales = feb.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(feb_sales);
        }

        if (mar.length !== 0) {
          let mar_sales = mar.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(mar_sales);

        }

        if (apr.length !== 0) {
          let apr_sales = apr.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(apr_sales);
        }

        if (may.length !== 0) {
          let may_sales = may.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(may_sales);
        }

        if (jun.length !== 0) {
          let jun_sales = jun.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(jun_sales);
        }

        if (jul.length !== 0) {
          let jul_sales = jul.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(jul_sales);
        }

        if (aug.length !== 0) {
          let aug_sales = aug.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(aug_sales);
        }


        if (sept.length !== 0) {
          let sept_sales = sept.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(sept_sales);
        }

        if (oct.length !== 0) {
          let oct_sales = oct.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(oct_sales);
        }



        if (nov.length !== 0) {
          let nov_sales = nov.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(nov_sales);
        }

        if (dec.length !== 0) {

          let dec_sales = dec.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          sales.push(dec_sales);

        }

        const static_labels = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",

        ];
        //const labels = Object.keys(parcelsPerMonth);

        setChartData({
          labels: static_labels,
          datasets: [
            {
              label: t('revenue'),
              data: sales,

              backgroundColor: "rgba(117, 142, 79, 0.3)",

              borderColor: "rgba(117, 142, 79, 1)",

              borderWidth: 1,
            },
          ],
        });
      }
      setLoading(false);
    } catch (err) { }
  }, [t]);
  useEffect(() => {
    fetchParcelsByAgency();
  }, [fetchParcelsByAgency]);

  return data.length === 0? (<React.Fragment>
    <div className="page-content">
      <Container className="">
        <div className="loader-outer-bar">
          <div className="loader-inner">
          <p>{t('no_parcel')}.</p>
          </div>
        </div>
      </Container>
    </div>
  </React.Fragment>):
  loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container className="">
          <div className="loader-outer-bar">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <div className="App">
      <div>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            title: { text: t('parcel_income'), display: true },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }}
        />
      </div>
    </div>
  );
};
export default withNamespaces()(ParcelsChart);
