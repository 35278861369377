import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { updateAction, updateBooking } from "../../../utils/updateActions";
import moment from "moment";
import { MDBDataTable } from "mdbreact";


import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import BookingModal from "../../../components/Modals/BookingModal";

import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";
import { withNamespaces } from 'react-i18next';

function PendingBookings({ t }) {
  const [loading, setLoading] = useState(true);
  const { actions, state } = useStateMachine({ updateAction, updateBooking });


  const [breadcrumb] = useState([
    { title: t('Agency'), link: "#" },
    { title: t('booked_tickets'), link: "#" },
  ]);

  const [editModal, setEditModal] = useState({
    modal_static: false,
  });

  const showEditModal = () => {
    setEditModal({ modal_static: true });

  };

  const bookingRef = useRef({});
  const getCurrentBooking = (booking) => {
    bookingRef.current = booking;
    actions.updateAction({ trip: bookingRef.current });
  };


  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchBookingsByLocation = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/bookings/teller-location-bookings`
        );

        const values = Object.values(data);
        setData(values[0]);
        toast.success(t('success'))
        setLoading(false);
      } catch (err) {

        setLoading(false);
        toast.error(t('failed'));


      }
    };

    fetchBookingsByLocation();
  }, [t]);

  const fetchBookings = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/bookings/teller-location-bookings`
      );

      const values = Object.values(data);
      setData(values[0]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);


    }
  };



  const Actions = ({ booking }) => {
    return (
      <>
        <Link
          to="#"
          className="mr-3 text-success"
          id="edit1"
          onClick={() => { getCurrentBooking(booking); showEditModal() }}
        >
          <i className="mdi mdi-pencil font-size-18"></i>
        </Link>
        <UncontrolledTooltip placement="top" target="edit1">
          Edit
        </UncontrolledTooltip>
        <Link
          to="#"
          className="mr-3 text-primary"
          id="print1"
          onClick={() => { getCurrentBooking(booking); showEditModal() }}
        >
          <i className="mdi mdi-print font-size-18"></i>
        </Link>
        <UncontrolledTooltip placement="top" target="print1">
          Print
        </UncontrolledTooltip>
      </>
    );
  };

  const tripData = {
    columns: [
      {
        label: t('ticket_number'),
        field: "ticket",
      },
      {
        label: t('name'),
        field: "name",
      },
      {
        label: t('id_card'),
        field: "idcard",
      },
      {
        label: t('phone'),
        field: "phone",
      },

      {
        label: t('from'),
        field: "from",
      },
      {
        label: t('to'),
        field: "to",
      },
      {
        label: t('departure_time'),
        field: "departure",
      },

      {
        label: t('journey_date'),
        field: "journeyDate",
      },
      {
        label: t('seat_no'),
        field: "seat",
      },

      {
        label: t('fare'),
        field: "price",
      },

      {
        label: t('booking_fee'),
        field: "bookingFee",
      },

    ],
    rows: data.map((trip) => {
      return {
        ticket: trip.ticketNumber,
        name: trip.name,
        idcard: trip.idCardNo,
        phone: trip.phoneNumber,
        from: trip.departureCity,
        to: trip.arrivalCity,
        departure: trip.departureTime,

        journeyDate: moment(trip.journeyDate).format("MM-DD-YYYY"),
        seat: trip.seat,

        price: trip.price,

        bookingFee: trip.booking_fee,
      

        actions: <Actions trip={trip} />
      };
    }),
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={t('Bookings')} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pt-0">
                  <Nav tabs className="nav-tabs-custom mb-4"></Nav>
                  <MDBDataTable noBottomColumns responsive data={tripData} className="mt-4" />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <BookingModal
            getModal={setEditModal}
            modal={editModal}

            title={t("sell_ticket")}
            fetchTrips={fetchBookings}

            buttonText={t("save")}
            controlText={'Edit'}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(PendingBookings);
