import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardBody, Media } from "reactstrap";

import { withNamespaces } from "react-i18next";

function BookingSummary({t}) {
  const [bookings, setBookings] = useState(0);
  const [icons] = useState({
    reports: [
      { icon: "ri-stack-line", title: t('Users') },
      { icon: "ri-store-2-line", title: t('Bookings') },
      { icon: "ri-briefcase-4-line", title: t('parcels') },
    ],
  });

  useEffect(() => {
    fetchBooking();
  }, []);

  const fetchBooking = async () => {
    try {
      const { data } = await axios.get("/api/v1/bookings/all-bookings");

      const values = Object.values(data);
      setBookings(values[0].length);
    } catch (err) {}
  };

  return (
    <Card>
      <CardBody>
        <Media>
          <Media body className="overflow-hidden">
            <p className="text-truncate font-size-14 mb-2">
              {icons.reports[1].title}
            </p>
            <h4 className="mb-0">{bookings}</h4>
          </Media>
          <div className="text-primary">
            <i className={icons.reports[1].icon + " font-size-24"}></i>
          </div>
        </Media>
      </CardBody>
    </Card>
  );
}

export default withNamespaces() (BookingSummary);
