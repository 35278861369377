import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FadeLoader } from "react-spinners";
import { withNamespaces } from "react-i18next";
import { useStateMachine } from "little-state-machine";
import { updateParcel } from "../../utils/updateActions";
import DatePicker from 'react-datepicker'
import { customStyles } from "../../utils/styles";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";

const towns = [
  { label: "Bafoussam", value: "Bafoussam" },
  { label: "Bamenda", value: "Bamenda" },

  { label: "Buea", value: "Buea" },
  { label: "Douala", value: "Douala" },
  { label: "Dschang", value: "Dschang" },
  { label: "Edea", value: "Edea" },
  { label: "Garoua", value: "Garoua" },
  { label: "Kribi", value: "kribi" },
  { label: "Kumba", value: "Kumba" },
  { label: "Limbe", value: "Limbe" },
  { label: "Maroua", value: "Maroua" },
  { label: "Nkongsamba", value: "Nkongsamba" },
  { label: "Yaounde", value: "Yaounde" },
];
function ParcelModal({
  modal,
  getModal,
  title,
  parcel,

  fetchParcels,
  t,
  buttonText,
  controlText,
}) {
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();

  const { actions } = useStateMachine({ updateParcel });
  const [loading, setLoading] = useState(false);
  const [selectedFrom, setSelectedFrom] = useState(null);
  const [selectedTo, setSelectedTo] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const status = [
    { label: t('received'), value: t('received') },
    { label: t('sent'), value: t('sent') },
    { label: t('collected'), value: t('collected') },
  ]

  const handleChange = e => {
    setSelectedFrom(e.value);
  }

  const handleToChange = e => {
    setSelectedTo(e.value);
  }

  const handleStatusChange = e => {
    setSelectedStatus(e.value);
  }


  useEffect(() => {
    reset({
      data: parcel,
    });
  }, [parcel, reset]);

  const addParcel = async (formData) => {
    formData.from = selectedFrom;
    formData.to = selectedTo;
    formData.status = selectedStatus;
    try {
      const { data } = await axios.post(`api/v1/parcels`, formData);

      if (data?.error) {
        toast.error(data.message);
      } else {
        fetchParcels();
        actions.updateParcel({ parcel: data });


        history.push("/parcel-summary");
        getModal({ modal_static: false });

      
      }
    } catch (error) {


      toast.error(error.response.data.message);
    }
  };

  const modifyParcel = async (formData) => {
    if (selectedFrom === null){
      formData.from = parcel?.from;
    }else{
      formData.from = selectedFrom
    }
    if (selectedTo === null){
      formData.to = parcel?.to;
    }else{
      formData.to = selectedTo
    }
    if (selectedStatus === null){
      formData.status = parcel?.status;
    }else{
      formData.status = selectedStatus;
    }
    try {
      const { data } = await axios.put(`/api/v1/parcels/${parcel.id}`, formData);
      if (data?.error) {
        toast.error(data.message);
      } else {
        fetchParcels();
        getModal({ modal_static: false });
        
      }
    } catch (error) {


      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal isOpen={modal.modal_static} backdrop="static" centered size="lg">
      <ModalHeader toggle={() => getModal({ modal_static: false })}>
        {title}
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={
            controlText === "Add"
              ? handleSubmit(addParcel)
              : handleSubmit(modifyParcel)
          }
        >
          <Row>
            {controlText === "editStatus" ? (null) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="name">{t('sender_name')}</label>
                <input
                  defaultValue={parcel?.senderName}
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  {...register("senderName", {
                    required: true,
                    minLength: 6
                  })}
                />
              </div>
              {errors.name && <p style={{ color: 'red' }}>{t('agency_branch_err')}</p>}
            </Col>)}
            {controlText === "editStatus" ? (null) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="name">{t('receiver_name')}</label>
                <input
                  defaultValue={parcel?.receiverName}
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  {...register("receiverName", {
                    required: true,
                    minLength: 6
                  })}
                />
              </div>
              {errors.name && <p style={{ color: 'red' }}>{t('agency_branch_err')}</p>}
            </Col>)}

            {controlText === "editStatus" ? (null) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="senderPhone">{t('sender_phone')}</label>
                <input
                  defaultValue={parcel?.senderPhone}
                  type="number"
                  name="senderPhone"
                  id="senderPhone"
                  className="form-control"
                  {...register("senderPhone", {
                    required: true,
                    minLength: 9,
                    maxLength: 9
                  })}
                />
              </div>
              {errors.senderPhone && <p style={{ color: 'red' }}>{t('phone_err')}</p>}
            </Col>)}


            {controlText === "editStatus" ? (null) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="receiverPhone">{t('receiver_phone')}</label>
                <input
                  defaultValue={parcel?.receiverPhone}
                  type="number"
                  name="receiverPhone"
                  id="receiverPhone"
                  className="form-control"
                  {...register("receiverPhone", {
                    required: true,
                    minLength: 9,
                    maxLength: 9
                  })}
                />
              </div>
              {errors.receiverPhone && <p style={{ color: 'red' }}>{t('phone_err')}</p>}
            </Col>)}
            {controlText === "editStatus" ? (null) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="from">{t('from')}</label>
                <Controller
                  control={control}
                  name="from"
                  render={() => (

                <Select
                styles={customStyles}
                value={towns.find(obj => obj.value === selectedFrom)} 
                defaultValue={parcel?.from}
                isClearable
                isSearchable
                options={towns} 
                onChange={handleChange}
                placeholder={parcel?.from}
                  type="text"
                  
                  id="from"
                 
                 
                />
                )}
                />
              </div>
              {errors.from && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>
            )}

            {controlText === "editStatus" ? (null) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="to">{t('to')}</label>
                <Controller
                  control={control}
                  name="to"
                  render={() => (

                <Select
                styles={customStyles}
                value={towns.find(obj => obj.value === selectedTo)} 
                defaultValue={parcel?.to}
                options={towns} 
                isClearable
                isSearchable
                onChange={handleToChange}
                placeholder={parcel?.to}
                  type="text"
                  
                  id="to"
                
                 
                />
                )}
                />
              </div>
              {errors.to && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>
            )}

            {controlText === "editStatus" ? (null) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="price">{t('price')}</label>
                <input
                  defaultValue={parcel?.price}
                  type="number"
                  name="price"
                  id="price"
                  className="form-control"
                  {...register("price", {
                    required: true,

                  })}
                />
              </div>
              {errors.price && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>)}

            {controlText === "editStatus" ? (null) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="parcelType">{t('parcel_type')}</label>
                <input
                  defaultValue={parcel?.parcelType}
                  type="text"
                  name="parcelType"
                  id="parcelType"
                  className="form-control"
                  {...register("parcelType", {
                    required: true,

                  })}
                />
              </div>
              {errors.parcelType && <p style={{ color: 'red' }}>{t('parcel_type')}</p>}
            </Col>)}


            {controlText === "editStatus" ? (null) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="receivedDate">{t('received_date')}</label>
                <Controller
                  control={control}
                  name="receivedDate"

                  render={({ field }) => (

                    <DatePicker
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      type="date"
                      defaultValue={""}
                      minDate={moment().toDate()}
                      placeholderText={t('select_date')}
                      className="form-control"
                    />

                  )}
                />

              </div>
              {errors.receivedDate && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>)}

           {/* {controlText === "editStatus" ? (null) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="arrivalDate">{t('arrival_date')}</label>
                <Controller
                  control={control}
                  name="arrivalDate"

                  render={({ field }) => (

                    <DatePicker
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      defaultValue={field.value = moment(parcel?.arrivalDate).format("DD-MM-YYYY")}
                      type="date"
                      minDate={moment().toDate()}
                      placeholderText="Click to select date"
                      className="form-control"
                    />

                  )}
                />

              </div>
              {errors.arrivalDate && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>)}*/}

           {/* {controlText === "editStatus" ? (null) : (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="vehicleNo">{t('vehicle_no')}</label>
                <input
                  defaultValue={parcel?.vehicleNumber}
                  type="text"
                  name="vehicleNo"
                  id="vehicleNo"
                  className="form-control"
                  {...register("vehicleNumber", {
                    required: true,
                  })}
                />
              </div>
              {errors.vehicleNumber && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>)}*/}


            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="status">{t('status')}</label>
                <Controller
                  control={control}
                  name="status"
                  render={() => (

                <Select
                styles={customStyles}
                value={status.find(obj => obj.value === selectedStatus)} 
                defaultValue={parcel?.status}
                options={status} 
                onChange={handleStatusChange}
                placeholder={parcel?.status}
                  type="text"
                  
                  id="status"
                
                 
                />
                )}
                />
              </div>
              {errors.status && <p style={{ color: 'red' }}>{t('required_field')}</p>}
                </Col>


          </Row>

          <ModalFooter>
            <Button

              color="light"
              onClick={() => getModal({ modal_static: false })}
            >
              {t('cancel')}
            </Button>
            {loading ? (<FadeLoader color="BCC015" />) : (<Button type="submit" color="primary">
              {buttonText}
            </Button>)}
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default withNamespaces()(ParcelModal);
