import React from "react";
import {

  Button
} from "reactstrap";

import { Link } from "react-router-dom";

import LanguageDropdown from "../Common/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../Common/TopbarDropdown/Profile";
import { withNamespaces } from "react-i18next";

import logo from '../../assets/images/transapp-logo.svg';


function Header(props) {
  //const { state } = useStateMachine(updateUser);
  function toggleMenu() {
    props.toggleMenuCallback();
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {

      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }



  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box">
              <Link to="#" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" width="70" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="40" />
                </span>
              </Link>

              <Link to="#" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="40" />
                </span>
              </Link>
            </div>

            <Button size="lg" color="none" type="button" onClick={toggleMenu} className=" px-5 font-size-24 header-item waves-effect" id="vertical-menu-btn">
              <i className="ri-menu-2-line align-middle"></i>
            </Button>



          </div>

          <div className="d-flex">


            <LanguageDropdown />



            <div className="dropdown d-none d-lg-inline-block ml-1">
              <Button color="none" type="button" className="header-item noti-icon waves-effect" onClick={toggleFullscreen}>
                <i className="ri-fullscreen-line"></i>
              </Button>
            </div>
            <ProfileMenu />

          </div>
        </div>
      </header>
    </React.Fragment>
  );



}

export default (withNamespaces()(Header));
