/* eslint-disable no-unused-vars */
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { FadeLoader } from "react-spinners";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-GB";
import ptBR from "date-fns/locale/pt-BR";
import { withNamespaces } from "react-i18next";
import React, { useState } from "react";
import { useStateMachine } from "little-state-machine";
import { customStyles } from "../../utils/styles";
import Select from "react-select";
import { updateUser } from "../../utils/updateActions";

import axios from "axios";
import { toast } from "react-hot-toast";
import moment from "moment";

const key = localStorage.getItem('i18nextLng');

let tripTypes = [ { label: "Regular", value: "Regular" },  { label: "VIP", value: "VIP" },]


function TripEditModal({
  modal,
  getModal,
  title,
  trip,
  fetchTrips,

  buttonText,
  t,
}) {
 
  const { from, to, departure, arrival, journeyDate, seats, price, boarding_time, busType } =
    trip;

  const { state } = useStateMachine(updateUser);
  const [toCity, setToCity] = useState(null);
 
  const [selectedOption, setSelectedOption] = useState(null);
  const [type, setType] = useState(null);
  const [startDate, setStartDate] = useState();
  const [depDate, setDepDate] = useState();
  const [arrDate, setArrDate] = useState();
  const [depDate1, setDepDate1] = useState(new Date());
  const [arrDate1, setArrDate1] = useState(new Date());
  const [boardingTime, setBoardingTime] = useState(new Date());
  const [boardingTime1, setBoardingTime1] = useState();
  const [loading, setLoading] = useState(false);
 

  const [values, setValues] = useState({
    from: selectedOption?.value,
    to: toCity,
    departure: depDate,
    arrival: arrDate,
    journeyDate: startDate,
    seats: '',
    price: '',
    duration: "",
    boarding_time: boardingTime1,
    tripType: type,
  });

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };


  let towns = [
    { label: "Bafoussam", value: "Bafoussam" },
    { label: "Bamenda", value: "Bamenda" },

    { label: "Buea", value: "Buea" },
    { label: "Douala", value: "Douala" },
    { label: "Dschang", value: "Dschang" },
    { label: "Edea", value: "Edea" },
    { label: "Garoua", value: "Garoua" },
    { label: "Kribi", value: "kribi" },
    { label: "Kumba", value: "Kumba" },
    { label: "Limbe", value: "Limbe" },
    { label: "Maroua", value: "Maroua" },
    { label: "Nkongsamba", value: "Nkongsamba" },
    { label: "Yaounde", value: "Yaounde" },
  ];
 

  const updateTrip = async (event) => {
     setLoading(true);
    event.preventDefault();
    let fromCity, arrCity, out, arr, tripDate, available, fare, time_taken, boarding_at, tripType = null;
    if (selectedOption?.value === undefined) {
      fromCity = from;
    } else {
      fromCity = selectedOption.value
    }
    if (toCity?.value === undefined) {
      arrCity = to;
    } else {
      arrCity = toCity.value
    }
    if (depDate === undefined) {
      out = departure;
    } else {
  
      out = moment(depDate1, "HH:MM A").format("HH:MM a")
    }
    if (arrDate === undefined) {
      arr = arrival;
    } else {
      arr = moment(arrDate1, "HH:MM A").format("HH:MM a")
    }
    if (startDate === undefined) {
      tripDate = journeyDate
    } else {
      tripDate = startDate
    }

    if (values?.seats === '') {
      available = seats
    } else {
      available = values.seats
    }
    if (values?.price === '') {
      fare = price
    } else {
      fare = values.price
    }




    if (boardingTime1 === undefined) {
      boarding_at = boarding_time;
    } else {

      boarding_at = moment(boardingTime, "HH:MM A").format("HH:MM a")
    }


    if (type?.value  === undefined) {
      tripType = busType
    } else {
      tripType = type.value
    }

    let formData ={
      from: fromCity,
      to:  arrCity,
      departure: out,
      arrival: arr,
      journeyDate: tripDate,
      seats: available,
      tripType: tripType,
      price: fare,

    }
    if (state.user.data.data.permissions === "CRUD_teller") {
      try {
        const { data } = await axios.put(`api/v1/trips/${trip.id}`, formData);
        if (data?.error) {
          toast.error(data.message);
        } else {
          setLoading(false);
          fetchTrips();
          getModal({ modal_static: false });
          toast.success(data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
    if (state.user.data.data.permissions === "CRUD_agency") {
      try {
        const { data } = await axios.put(
          `api/v1/trips/trip-update/${trip.id}`,
          formData
        );
        if (data?.error) {
          toast.error(data.message);
        } else {
          setLoading(true);
          fetchTrips();
          getModal({ modal_static: false });
          toast.success(data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <Modal isOpen={modal.modal_static} backdrop="static" centered size="lg">
      <ModalHeader toggle={() => getModal({ modal_static: false })}>
        {title}
      </ModalHeader>
      <ModalBody>
        <form>
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="from">{t("from")}</label>

                <Select
                  options={towns}

                  defaultInputValue={from}
                  onChange={setSelectedOption}


                  styles={customStyles}
                  isSearchable
                  name="from"
                
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="to">{t("to")}</label>
                <Select
                  options={towns}
                  onChange={setToCity}


                  defaultInputValue={to}
                  styles={customStyles}
                  isSearchable
                  name="to"
                  
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="departure">{t("departure")}</label>

                <DatePicker
                  selected={depDate}
                  onChange={(date) =>{
                    setDepDate(date)
                    setDepDate1(date)
                  }}
                  className="form-control"
                  
                  placeholderText={departure}
                  dateFormat="p"
                  showTimeSelect
                  
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  locale={ptBR}
                  
                  timeFormat="p"
                  type="time"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="arrival">{t("arrival")}</label>

                <DatePicker
                  selected={arrDate}
                  onChange={(date) => {
                    setArrDate(date)
                    setArrDate1(date)
                  }}
                  className="form-control"
                  placeholderText={arrival}
                
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  locale={ptBR}
                  dateFormat="p"
                  timeFormat="p"
                  type="time"
                />
              </div>
            </Col>
            {key === 'fr' ? <Col lg={6}>
              <div className="form-group">
                <label htmlFor="journeyDate">{t("journey_date")}</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={moment().toDate()}
                  type="date"
                  locale={fr}
                  placeholderText={trip.journeyDate}
                  className="form-control"
                />
              </div>
            </Col> : <Col lg={6}>
              <div className="form-group">
                <label htmlFor="journeyDate">{t("journey_date")}</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={moment().toDate()}
                  type="date"
                  locale={en}
                  placeholderText={journeyDate}
                  className="form-control"
                />
              </div>
            </Col>}

            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="boarding_time">{t("boarding_time")}</label>
                <DatePicker
                  selected={boardingTime1}
                  onChange={(date) => {
                    setBoardingTime(date)
                    setBoardingTime1(date)
                  }}
                  className="form-control"

                  placeholderText={moment(boarding_time).format('LT')}
                  showTimeSelect
                  showTimeSelectOnly

                  timeIntervals={30}
                  timeCaption="Time"
                  locale={ptBR}
                  dateFormat="p"
                  timeFormat="p"

                  type="time"
                />
              </div>
            </Col> 


            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="seats">{t("seats")}</label>
                <input
                  defaultValue={seats}
                  type="number"
                  name="seats"
                  id="seats"
                  onChange={handleChange("seats")}
                  className="form-control"

                />
              </div>
            </Col>

            
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="tripType">TripType</label>
                <Select
                  options={tripTypes}
                  onChange={setType}
                  styles={customStyles}
                  
                  type="text"
                  name="tripType"
                  defaultInputValue={trip.tripType}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="price">{t("price")}</label>
                <input
                  defaultValue={price}
                  type="number"
                  name="price"
                  id="price"
                  className="form-control"
                  onChange={handleChange("price")}
                />
              </div>
            </Col>
          </Row>

          <ModalFooter>
            <Button
              type="button"
              color="light"
              onClick={() => getModal({ modal_static: false })}
            >
              {t("cancel")}
            </Button>
            
            {loading ? (<FadeLoader color="BCC015" />) : (<Button type="submit" color="primary">
              {buttonText}
            </Button>)}
           
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default withNamespaces()(TripEditModal);
