import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
//import "./datatables.scss";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";
import { useStateMachine } from "little-state-machine";
import { updateUser } from "../../../utils/updateActions";

import LocationModal from "../../../components/Modals/LocationModal";
import DeleteConfirmation from "../../../components/Modals/DeleteConfirmation";
import { withNamespaces} from 'react-i18next';

function Locations({t}) {
  const [loading, setLoading] = useState(true);
  const {state} = useStateMachine(updateUser);
 
  const [breadcrumb] = useState([
    { title: state?.user?.data?.data?.agencyName, link: "#" },
    { title: t('Locations'), link: "#" },
  ]);
  const [data, setData] = useState([]);
  const [location, setLocation] = useState({});
  const locationRef = useRef({});
  const [modal, setModal] = useState({
    modal_static: false,
  });
  const [editModal, setEditModal] = useState({
    modal_static: false,
  });
  const [setAlert] = useState({
    isAlertOpen: false,
  });

  const [deleteMessage, setDeleteMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async (req, res) => {
    try {
      const { data } = await axios.get("api/v1/locations");

      const values = Object.values(data);
      setData(values[0]);

      setLoading(false);
      toast.success(data.message);

    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    
    }
  };

  const showModal = () => {
    const location = {};
    getCurrentlocation(location);

    setModal({ modal_static: true });
    setAlert({ isAlertOpen: false });
  };

  const openEditModal = () => {
    setEditModal({ modal_static: true });
    setAlert({ isAlertOpen: false });
  };

  const showDeleteModal = () => {
    setDisplayConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const deletelocation = async (formData) => {
    try {
      const { data } = await axios.delete(
        `api/v1/locations/${location.id}`,
        formData
      );
      if (data?.error) {
        toast.error(data.error);
      } else {
        fetchLocations();
        setModal({ modal_static: false });
        setDisplayConfirmationModal(false);
        toast.success(data.message);
      }
    } catch (error) {
     

      toast.error(error.response.data.message);
    }
  };

  const Actions = ({ location }) => {
    return (
      <>
        <Link
          to="#"
          className="mr-3 text-primary"
          id="edit1"
          onClick={() => {
            getCurrentlocation(location);
            openEditModal();
          }}
        >
          <i className="mdi mdi-pencil font-size-18"></i>
        </Link>
        

        <Link
          to="#"
          className="mr-3 text-danger"
          id="delete1"
          onClick={() => {
            getCurrentlocation(location);
            showDeleteModal();
          }}
        >
          <i className="mdi mdi-trash-can font-size-18"></i>
        </Link>
        
      </>
    );
  };

  const locationData = {
    columns: [
      {
        label: t('city'),
        field: "city",
      },
      {
        label: t('neighbourhood'),
        field: "neighbourhood",
      },
      {
        label: t('location_type'),
        field: "locationType",
      },
      {
        label: t('agency_branch'),
        field: "agencyBranch",
      },

      {
        label: t('country'),
        field: "country",
      },

      {
        label: t('action'),
        field: "actions",
      },
    ],
    rows: data.map((location) => {
      return {
        city: location.city,
        neighbourhood: location.neighbourhood,
        locationType: location.locationType,
        agencyBranch: location.agencyBranch,
        country: location.country,
        actions: <Actions location={location} />,
      };
    }),
  };

  const getCurrentlocation = (location) => {
    locationRef.current = location;
    setLocation(locationRef.current);
    setDeleteMessage(
      `${t('delete_location')}'${locationRef.current.city}'?`
    );
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={t('Locations')} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pt-3">
                  <div>
                    <Link
                      to="#"
                      onClick={() => {
                        showModal();
                      }}
                      className="btn btn-primary mb-2"
                    >
                      <i className="mdi mdi-plus mr-2"></i> {t('Add Location')}
                    </Link>
                  </div>
                  <MDBDataTable
                  noBottomColumns
                    responsive
                    data={locationData}
                    className="mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <LocationModal
            getModal={setModal}
            modal={modal}
            location={location}
            title={t("Add Location")}
            fetchLocations={fetchLocations}
            setAlert={setAlert}
            buttonText={t("Add")}
            controlText={'Add'}
          />

          <LocationModal
            getModal={setEditModal}
            modal={editModal}
            location={location}
            title={t("Edit Location")}
            fetchLocations={fetchLocations}
            setAlert={setAlert}
            buttonText={t("save")}
          />

          <DeleteConfirmation
            showModal={displayConfirmationModal}
            confirmModal={deletelocation}
            hideModal={hideConfirmationModal}
            message={deleteMessage}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default  withNamespaces() (Locations);

