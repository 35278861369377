import { useState, useEffect, createContext, useContext } from "react";
import axios from "axios";
import { useStateMachine } from "little-state-machine";
import { updateUser } from "../utils/updateActions";


const AuthContext = createContext();

// Hook to provide access to context object
export const useAuthContext = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const { state } = useStateMachine({ updateUser });
  const [auth, setAuth] = useState({
    user: null,
    token: "",
  });
 
  // config axios
   //axios.defaults.baseURL = 'http://localhost:5000';
  
//https://git.heroku.com/transapp-admin.git
axios.defaults.baseURL = ' https://api.bookontransapp.com';
axios.defaults.headers.common["Authorization"] = `Bearer ${state.user?.data?.token}`;
 
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      setAuth(JSON.parse(localStorage.getItem("authUser")));
    }
  }, []);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
