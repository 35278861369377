import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { FadeLoader } from "react-spinners";
import { withNamespaces } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import { customStyles } from "../../utils/styles";
import axios from "axios";
import { toast } from "react-hot-toast";


let towns = [
  { label: "Bafoussam", value: "Bafoussam" },
  { label: "Bamenda", value: "Bamenda" },

  { label: "Buea", value: "Buea" },
  { label: "Douala", value: "Douala" },
  { label: "Dschang", value: "Dschang" },
  { label: "Edea", value: "Edea" },
  { label: "Garoua", value: "Garoua" },
  { label: "Kribi", value: "kribi" },
  { label: "Kumba", value: "Kumba" },
  { label: "Limbe", value: "Limbe" },
  { label: "Maroua", value: "Maroua" },
  { label: "Nkongsamba", value: "Nkongsamba" },
  { label: "Yaounde", value: "Yaounde" },
];

function PersonnelModal({
  modal,
  getModal,
  title,
  personnel,
  fetchPersonnel,
  buttonText,
  controlText,
  t
}) {
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      data: personnel,
    });
  }, [personnel, reset]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);

  const admin = [
    { label: t('teller'), value: t('teller') },
    { label: t('teller_admin'), value: t('teller_admin') },
  
  
  ]
  const gender = [
    { label: t('male'), value: t('male') },
    { label: t('female'), value: t('female') },
  ]

  const handleChange = e => {
    setSelectedOption(e.value);
  }

  const handleAdminChange = e => {
    setSelectedAdmin(e.value);
  }

  const handleLocationChange = e => {
    setSelectedLocation(e.value);
  }

 

  const {
    name,
    surname,
    age,
    sex,
    phoneNumber,
    email,
    location,
    idCardNumber,
    personnelType,
  } = personnel;

  const addPersonnel = async (formData) => {
    setLoading(true);
    formData.sex = selectedOption;
    formData.personnelType = selectedAdmin;
    formData.location = selectedLocation;
    
    try {
      const { data } = await axios.post("api/v1/Personnel", formData);
      if (data?.error) {
        toast.error(data.message);
      } else {
        getModal({ modal_static: false });
        formData.id = 0;

        fetchPersonnel();

      }
    } catch (error) {


      toast.error(error.response.data.message);
    }
  };

  const updatePersonnel = async (formData) => {
    
    if (selectedOption === null){
      formData.sex = sex;
    }else{
      formData.sex = selectedOption
    }
    if (selectedAdmin === null){
      formData.personnelType = personnelType;
    }else{
      formData.personnelType = selectedAdmin
    }
    if (selectedLocation === null){
      formData.location = location;
    }else{
      formData.location = selectedLocation;
    }
    try {
      const { data } = await axios.put(
        `api/v1/Personnel/${personnel.id}`,
        formData
      );
      if (data?.error) {
        toast.error(data.message);
      } else {
        fetchPersonnel();

        getModal({ modal_static: false });

      }
    } catch (error) {


      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal isOpen={modal.modal_static} backdrop="static" centered size="lg">
      <ModalHeader toggle={() => getModal({ modal_static: false })}>
        {title}
      </ModalHeader>
      <ModalBody>

        <form onSubmit={controlText === "Add" ?
          handleSubmit(addPersonnel) : handleSubmit(updatePersonnel)}>
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="name">{t('name')}</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  defaultValue={name}
                  className="form-control"
                  {...register("name", {
                    required: true,
                    minLength: 4,
                  })}
                />
              </div>
              {errors.name && <p style={{ color: 'red' }}>{t('city_err')}</p>}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="surname">{t('surname')}</label>
                <input
                  defaultValue={surname}
                  type="text"
                  name="surname"
                  id="surname"
                  className="form-control"
                  {...register("surname", {
                    required: true,
                    minLength: 4,
                  })}
                />
              </div>
              {errors.surname && <p style={{ color: 'red' }}>{t('city_err')}</p>}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="age">{t('age')}</label>
                <input
                  defaultValue={age}
                  type="number"
                  name="age"
                  className="form-control"
                  {...register("age", {
                    required: true,
                    minLength: 2
                  })}
                />
              </div>
              {errors.age && <p style={{ color: 'red' }} >{t('required_field')}</p>}
            </Col>
            <Col lg={6}>
              <div className="Form-control">
                <label htmlFor="sex">{t('sex')}</label>

                <Controller
                  control={control}
                  name="sex"
                  render={() => (
                <Select
                
                  value={gender.find(obj => obj.value === selectedOption)} // set selected value
                  placeholder={sex}
                  options={gender} // set list of the data
                  onChange={handleChange} // assign onChange function
                  styles={customStyles}
                  
                />
                  )}
                  />
                {/*} <select className="form-control"
                  type="text"
                  name="sex"
                  id="sex"
                 
                  {...register("sex", {
                    required: true
                  })}>
                     <option defaultValue>{sex}</option>
                  <option>{t('male')}</option>
                  <option>{t('female')}</option>

                </select>*/}

              </div>
              {errors.sex && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="email">{t('email_address')}</label>
                <input
                  defaultValue={email}
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  {...register("email", {
                    required: true,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}

                />
              </div>
              {errors.email && <p style={{ color: 'red' }}>{t('email_err')}</p>}
            </Col>
            {controlText === 'Add' ? (<Col lg={6}>
              <div className="form-group">
                <label htmlFor="password">{t('password')}</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  {...register("password", {
                    required: true,
                    minLength: 6
                  })}
                  defaultValue={""}
                />
              </div>
              {errors.password && <p style={{ color: 'red' }}>{t('password_err')}</p>}
            </Col>) :
              <Col lg={6}>
                <div className="form-group">
                  <label htmlFor="password">{t('password')}</label>
                  <input
                    disabled
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    {...register("password", {


                    })}
                    defaultValue={""}
                  />
                </div>
                {errors.password && <p style={{ color: 'red' }}>{t('password_err')}</p>}
              </Col>}
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="phoneNumber">{t('phone')}</label>
                <input
                  defaultValue={phoneNumber}
                  type="number"
                  name="phoneNumber"
                  id="phoneNumber"
                  className="form-control"
                  {...register("phoneNumber", {
                    required: true,
                    minLength: 9,
                    maxLength: 9,
                  })}
                />
              </div>
              {errors.phone && <p style={{ color: 'red' }}>{t('phone_err')}</p>}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="personnelType">{t('personnel_type')}</label>
                <Controller
                  control={control}
                  name="personnelType"
                  render={() => (
                <Select
                    styles={customStyles}
                  value={admin.find(obj => obj.value === selectedAdmin)} 
                  
                   placeholder={personnelType}
                  options={admin} // set list of the data
                  onChange={handleAdminChange} // assign onChange function
                />
                  )}
                  />
              {/*}  <select className="form-control"
                  type="text"
                  name="personnelType"
                  id="personnelType"
                  style={{ background: 'transparent !important;' }}
                  {...register("personnelType", {
                    required: true
                  })}>
                  {controlText !== 'Add' ? <option defaultValue>{personnelType}</option> : null}

                  <option>{t('teller')}</option>
                  <option>{t('teller_admin')}</option>

                </select>*/}

                {/* <input
                    defaultValue={personnelType}
                    type="text"
                    name="personnelType"
                    id="personnelType"
                    className="form-control"
                    {...register("personnelType", {
                      required: true
                    })}
                  />*/}
              </div>
              {errors.personnelType && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="location">{t('location')}</label>
                <Controller
                  control={control}
                  name="location"
                  render={() => (

                <Select
                styles={customStyles}
                value={towns.find(obj => obj.value === selectedLocation)} 
                defaultValue={location}
                options={towns} 
                isSearchable
                onChange={handleLocationChange}
                placeholder={location}
                  type="text"
                  
                  id="location"
                  className="form-control"
                 
                />
                )}
                />
              </div>
              {errors.location && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="idCardNumber">{t('id_card')}</label>
                <input
                  defaultValue={idCardNumber}
                  type="number"
                  name="idCardNumber"
                  id="idCardNumber"
                  className="form-control"
                  {...register("idCardNumber", {
                    required: true,
                  })}
                />
              </div>
              {errors.idCardNumber && <p style={{ color: 'red' }}>{t('password_err')}</p>}
            </Col>

            {/*<Col lg={6}>
              <div className="form-group">
                <label htmlFor="idValidTill">{t('expiry_date')}</label>
                <input
                  defaultValue={idValidTill}
                  type="text"
                  name="idValidTill"
                  id="idValidTill"
                  className="form-control"
                  {...register("idValidTill", {
                    required: true,
                  })}
                />
              </div>
              {errors.idValidTill && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>*/}
            {/* <Col lg={6}>
              <div className="form-group">
                <label htmlFor="driversLicenceNo">{t('drivers_licence')}</label>
                <input
                  defaultValue={driversLicenceNo}
                  type="text"
                  name="driversLicenceNo"
                  className="form-control"
                  {...register("driversLicenceNo", {})}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="dlValidTill">{t('expiry_date')}</label>
                <input
                  type="text"
                  name="dlValidTill"
                  className="form-control"
                  {...register("dlValidTill", {})}
                  defaultValue={dlValidTill}
                />
              </div>
            </Col>*/}
          </Row>

          <ModalFooter>
            <Button
              type="button"
              color="light"
              onClick={() => getModal({ modal_static: false })}
            >
              {t('cancel')}
            </Button>
            {loading ? (<FadeLoader color="BCC015" />) : (<Button type="submit" color="primary">
              {buttonText}
            </Button>)}
          </ModalFooter>
        </form>





      </ModalBody>
    </Modal>
  );
}

export default withNamespaces()(PersonnelModal);
