import React, { useState, useEffect, useCallback } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { Container } from "reactstrap";

import ScaleLoader from "react-spinners/ScaleLoader";
import { groupBy } from "lodash";
import { withNamespaces } from "react-i18next";

const BookingChart = ({ t }) => {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData]= useState([]);

  

  const setChart = useCallback(async () => {
    try {
      const { data } = await axios.get("api/v1/bookings/all-bookings");

      const values = Object.values(data);
      setData(values[0]);

      if (data !== null) {
        const mapByAgency = (booking) => booking.agencyName;
        const agencyBookings = groupBy(values[0], mapByAgency);

        const bookings = Object.values(agencyBookings).map(
          (booking) => booking.length
        );
        const agency_labels = Object.keys(agencyBookings);

        setChartData({
          labels: agency_labels,
          datasets: [
            {
              label: t('bookings'),
              data: bookings,

              backgroundColor: "rgba(117, 142, 79, 0.4)",

              borderColor: "rgba(117, 142, 79, 1)",
            },
          ],
        });
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [t]);

  useEffect(() => {
    setChart();
  }, [setChart]);

  return data.length === 0? (<React.Fragment>
    <div className="page-content">
      <Container className="">
        <div className="loader-outer-bar">
          <div className="loader-inner">
          <p>{t('no_booking')}.</p>
          </div>
        </div>
      </Container>
    </div>
  </React.Fragment>):
  loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container className="">
          <div className="loader-outer-bar">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <div className="">
      <div>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            title: { text: t('bookings_by_agency'), display: true },
            legend: {
              display: true,
              position: "bottom",
            },
          }}
        />
      </div>
    </div>
  );
};
export default withNamespaces()(BookingChart);
