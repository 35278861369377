import React, { Component } from "react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import { withNamespaces } from 'react-i18next';

class AdminSidebarContent extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };

  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {

      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }

    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      //const parent2 = parent.parentElement;


      return false;
    }
    return false;
  };

  render() {

    return (
      <React.Fragment>
        {this.props.permissions === 'CRUD_admins' ? (<div id="sidebar-menu">

          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t('Menu')}</li>

            <li>
              <Link to="/owner-dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('Dashboard')}</span>
              </Link>
            </li>
            <li>
              <Link to="/agencies" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('Agencies')}</span>
              </Link>
            </li>
            <li>
              <Link to="/all-bookings" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('Bookings')}</span>
              </Link>
            </li>



          </ul>
        </div>) : (this.props.permissions === 'CRUD_agency' ? (<div id="sidebar-menu">

          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t('Menu')}</li>

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('Dashboard')}</span>
              </Link>
            </li>

            <li>
              <Link to="/trips-list" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('Trips')}</span>
              </Link>
            </li>



            <li>
              <Link to="/vehicles" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('Vehicles')}</span>
              </Link>
            </li>
            <li>
              <Link to="/locations" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('Locations')}</span>
              </Link>
            </li>
            <li>
              <Link to="/agency-personnel" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('Personnel')}</span>
              </Link>
            </li>
             

          </ul>
        </div>) : (<div id="sidebar-menu">

          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t('Menu')}</li>
            <li>
              <Link to="/teller-dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('Dashboard')}</span>
              </Link>
            </li>
                {this.props.personnelType === 'Teller Admin' || this.props.personnelType === 'Administrateur de guichet' ? (<li>
              <Link to="/trips" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('Trips')}</span>
              </Link>
            </li>) : (null)}



            <li>
              <Link to="/tickets" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('Tickets')}</span>
              </Link>
            </li>
                {/*<li>
                  <Link to="/pending-bookings-list" className="waves-effect">
                    <i className="ri-dashboard-line"></i>
                    <span className="ml-1">{this.props.t('pending_booking')}</span>
                  </Link>
            </li>*/}

            <li>
              <Link to="/register-parcel" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('register_parcel')}</span>
              </Link>
            </li>

            <li>
              <Link to="/discharge-parcel" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t('discharge_parcel')}</span>
              </Link>
            </li>






          </ul>
        </div>))}

      </React.Fragment>
    );


  }
}

export default withRouter((withNamespaces()(AdminSidebarContent)));
