import React, { useState, useEffect, useCallback } from "react";

import axios from "axios";
import { Card, CardBody, Media } from "reactstrap";

import { withNamespaces } from "react-i18next";

const date = new Date();
let monthTotal = 0;

const MonthlySummary = ({ t }) => {

  //const d = new Date();
 
  const defaultVal = date.toLocaleString('default', { month: 'short' });
  const [data, setData] = useState([]);
  //let monthTotal = 0;
  const [sales, setSales] = useState(monthTotal);
  const [show, setShow] = useState(true);
 

 
  const [icons] = useState({
    reports: [
      { icon: "ri-stack-line", title: t('today') },
      { icon: "ri-store-2-line", title: t('sales_month') },
      { icon: "ri-briefcase-4-line", title: t('parcels') },
    ],
  });

  const getBookings =  useCallback  (async() => {
  
  
      try {
        const { data } = await axios.get(`/api/v1/bookings`);
  
        const values = Object.values(data);
        setData(values[0]);
        const filterMonth = data.filter((booking) => {
  
          return (
            booking.month ===
            date.toLocaleString('default', { month: 'short' })
          );
        });
    
        for (let i = filterMonth.length - 1; i >= 0; i--) {
          let total = filterMonth[i].price;
    
          monthTotal += total;
          
          
        }
        
        setSales(monthTotal)
       
        //getDefaultMonthSalesTotal();
  
  
      } catch (err) { }
   
  }, [])
 

  useEffect(() => {
    getBookings();
    
    
  }, [getBookings]);

  const getDefaultMonthSalesTotal = useCallback(() => {


    const filterMonth = data.filter((booking) => {
     
      return (
        booking.month ===
        date.toLocaleString('default', { month: 'short' })
      );
    });

    for (let i = filterMonth.length - 1; i >= 0; i--) {
      let total = filterMonth[i].price;

      monthTotal += total;
      

    }
   
  },[data]);

  getDefaultMonthSalesTotal();
  
  
  function getMonthSalesTotal(event) {
   let selectedMonth = 0;
    
    
    const filterMonth = data.filter((booking) => {

      return (
        booking.month ===
        event.target.value
      );
    });
    
  
    for (let i = filterMonth.length - 1; i >= 0; i--) {
      let total = filterMonth[i].price;

      selectedMonth += total;
      
     
     
    }
    setSales(selectedMonth);
    setShow(false);
  }

 
  



  return (

    <Card>
      <CardBody>
        <div className="float-right">
          <select
            name="month-list"
            id="month-list"
            onChange={getMonthSalesTotal}
            className="custom-select custom-select-sm" >
            <option defaultValue>{defaultVal}</option>
            <option value="Jan">Jan</option>
            <option value="Feb">Feb</option>
            <option value="Mar">Mar</option>
            <option value="Apr">Apr</option>
            <option value="May">May</option>
            <option value="Jun">Jun</option>
            <option value="Jul">Jul</option>
            <option value="Aug">Aug</option>
            <option value="Sep">Sep</option>
            <option value="Oct">Oct</option>
            <option value="Nov">Nov</option>
            <option value="Dec">Dec</option>

          </select>
        </div>
        <Media>
          <Media body className="overflow-hidden">
            <p className="text-truncate font-size-14 mb-2 ">
              {icons.reports[1].title}
            </p>
            {show? <h4 className="mb-0">{monthTotal}</h4>: <h4 className="mb-0">{sales}</h4>}
          </Media>
          {/*<div className="text-primary">
            <i className={icons.reports[1].icon + " font-size-24 mr-3"} ></i>
  </div>*/}
        </Media>
      </CardBody>
    </Card>
  );
};
export default withNamespaces()(MonthlySummary);
