import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { MDBDataTable } from "mdbreact";
import classnames from "classnames";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import TripEditModal from "../../../components/Modals/TripEditModal";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import DeleteConfirmation from "../../../components/Modals/DeleteConfirmation";
import { useStateMachine } from "little-state-machine";
import { updateUser } from "../../../utils/updateActions";

import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";
import TripModal from "../../../components/Modals/TripModal";

function Trips({t}) {
 
  const [loading, setLoading] = useState(true);
  const trip_blank = {};
  const {state} = useStateMachine(updateUser);
  const [tab, setTab] = useState({ activeTabJustify: "1" });
  const [breadcrumb] = useState([
    { title: state?.user?.data?.data?.agencyName, link: "#" },
    { title: t('Trips'), link: "#" },
  ]);

  const [data, setData] = useState([]);
  const [modal, setModal] = useState({
    modal_static: false,
  });
  const [editModal, setEditModal] = useState({
    modal_static: false,
  });
  document.body.setAttribute('style', 'background: #f8f8f8!important;')

  const [trip, setTrip] = useState({ trip: null });
  const tripRef = useRef({});

  const [deleteMessage, setDeleteMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  function toggleCustomJustified(currentTab) {
    if (tab.activeTabJustify !== currentTab) {
      setTab({
        activeTabJustify: currentTab,
      });
    }
  }

  const filterDay = data.filter((trip) => {
    return (
      trip.journeyDate ===
      moment(new Date()).format("MM-DD-YYYY")
    );
  });
  const filterUpcoming = data.filter((trip) => {
    return (
      trip.journeyDate >
      moment(new Date()).format("MM-DD-YYYY")
    );
  });

  const filterPast = data.filter((trip) => {
    return (
      trip.journeyDate <
      moment(new Date()).format("MM-DD-YYYY")
    );
  });
  useEffect(() => {
   
    const fetchTrips = async () => {
      if(state.user.data.data.permissions === 'CRUD_teller'){
        try {
          const { data } = await axios.get("api/v1/trips");
    
          const values = Object.values(data);
          setData(values[0]);
    
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toast.error(error.response.data.message);
          
        }
      }else{
        try {
          const { data } = await axios.get("api/v1/trips/trip-list");
    
          const values = Object.values(data);
          setData(values[0]);
    
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toast.error(error.response.data.message);
          
        }
      }
     
    };
  
    fetchTrips();
  }, [t, state.user.data.data.permissions]);

 
  const showModal = () => {
    const trip = {};
    getCurrentTrip(trip);

    setModal({ modal_static: true });
   
  };

  const openEditModal = () => {
    setEditModal({ modal_static: true });
  
  };

  const showDeleteModal = () => {
    setDisplayConfirmationModal(true);
  };

  const getCurrentTrip = async (trip) => {
    setTrip(trip);
    tripRef.current = trip;

    setDeleteMessage(
      `${t('delete_trip')} '${moment(trip.departure).format('LT')}'?`
    );
  };

  const fetchTrips = async () => {
  
    if(state.user.data.data.permissions === 'CRUD_teller'){
      try {
        const { data } = await axios.get("api/v1/trips");
  
        const values = Object.values(data);
        setData(values[0]);
  
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
        
      }
    }else{
      try {
        const { data } = await axios.get("api/v1/trips/trip-list");
  
        const values = Object.values(data);
        setData(values[0]);
  
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
        
      }
    }
   
  };


  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const deleteTrip = async (formData) => {
    if(state.user.data.data.permissions === 'CRUD_teller'){
      try {
        const { data } = await axios.delete(`api/v1/trips/${tripRef.current.id}`, formData);
        if (data?.error) {
          toast.error(data.message);
        } else {
          fetchTrips();
          setModal({ modal_static: false });
          setDisplayConfirmationModal(false);
          toast.success(data.message);
        }
      } catch (error) {
      
  
        toast.error(error.response.data.message);
      }
      
    }
    if(state.user.data.data.permissions === 'CRUD_agency'){
      try {
        const { data } = await axios.delete(`api/v1/trips/trip-delete/${tripRef.current.id}`, formData);
        if (data?.error) {
          toast.error(data.message);
        } else {
          fetchTrips();
          setModal({ modal_static: false });
          setDisplayConfirmationModal(false);
          toast.success(data.message);
        }
      } catch (error) {
      
  
        toast.error(error.response.data.message);
      }
      
    }
    
  };

  const Actions = ({ trip }) => {
    return  moment(new Date()).format("MM-DD-YYYY") >
      trip.journeyDate  || trip.status === "Fully booked" ?(
      <>
       
      </>
    ) : (
      <>
          <Link
            to="#"
            className="mr-3 text-primary"
            id={"edit" + trip.id}
            onClick={() => {
              getCurrentTrip(trip);

              openEditModal();
            }}
          >
            <i className="mdi mdi-pencil font-size-18"></i>
          </Link>

          <Link
            to="#"
            className="text-danger"
            id={"delete" + trip.id}
            onClick={() => {
              getCurrentTrip(trip);

              showDeleteModal();
            }}
          >
            <i className="mdi mdi-trash-can font-size-18"></i>
          </Link>
      </>
    );
  };


  const tripData = {
    columns: [
      {
        label: t('from'),
        field: "from",
      },
      {
        label: t('to'),
        field: "to",
      },
      {
        label: t('departure'),
        field: "departure",
      },
      {
        label: t('arrival'),
        field: "arrival",
      },
      {
        label: t('journeyDate'),
        field: "journeyDate",
      },
      {
        label: t('seats'),
        field: "seats",
      },
      {
        label: t('status'),
        field: "status",
      },
      {
        label: t('category'),
        field: "tripType",
      },
     
      {
        label: t('price'),
        field: "price",
      },
      {
        label: t('action'),
        field: "actions",
      },
    ],
    rows: data.map((trip) => {
      return {
        from: trip.from,
        to: trip.to,
        
        departure: trip.departure,
        arrival: trip.arrival,
        journeyDate: moment(trip.journeyDate).format("DD-MM-YYYY"),
        seats: trip.seats,
        status:   trip.status === "Fully booked" ? (
          <div className="badge badge-soft-warning font-size-12">
            {t("fully_booked")}
          </div>
        ) : trip.status === "Past date" ||
          moment(new Date()).format("MM-DD-YYYY") >
           trip.journeyDate ? (
          <div className="badge badge-soft-danger font-size-12">
            {t("past")}
          </div>
        ) : (
          <div className="badge-soft-success font-size-12">
            {" "}
            {t("yet_to_start")}
          </div>
          ),
          tripType: trip.tripType,
        price: trip.price,


        actions: < Actions trip = { trip } />,
      };
    }),
  };

  const tripFiltered1 = {
    columns: tripData.columns,
    rows: filterDay.map((trip) => {
      return {
        from: trip.from,
        to: trip.to,
        departure: trip.departure,
        arrival: trip.arrival,
        journeyDate: moment(trip.journeyDate).format("MM-DD-YYYY"),
        seats: trip.seats,
        status: trip.status === "Fully booked" ? (
          <div className="badge badge-soft-warning font-size-12">
            {t("fully_booked")}
          </div>
        ) : trip.status === "Past date" ||
          moment(new Date()).format("MM-DD-YYYY") >
          trip.journeyDate ? (
          <div className="badge badge-soft-danger font-size-12">
            {t("past")}
          </div>
        ) : (
          <div className="badge-soft-success font-size-12">
            {" "}
            {t("yet_to_start")}
          </div>
        ),
        tripType: trip.tripType,
        price: trip.price,


        actions: < Actions trip={trip} />,
      };
    }),
  };

  const tripFiltered2 = {
    columns: tripData.columns,
    rows: filterUpcoming.map((trip) => {
      return {
        from: trip.from,
        to: trip.to,
        departure: trip.departure,
        arrival: trip.arrival,
        journeyDate: moment(trip.journeyDate).format("MM-DD-YYYY"),
        seats: trip.seats,
        status:
          trip.status === "Fully booked" || trip.status === 'Plein' ? (
            <div className="badge badge-soft-warning font-size-12">
              {t("fully_booked")}
            </div>
          ) :
            moment(new Date()).format("MM-DD-YYYY") >
              trip.journeyDate ? (
              <div className="badge badge-soft-danger font-size-12">
                {t("past")}
              </div>
            ) : (
              <div className="badge-soft-success font-size-12">

                {t("yet_to_start")}
              </div>
            ),
        tripType: trip.tripType,
        price: trip.price,
        // vehicleNo: trip.vehicleNo,
        reserved: trip.reserved,
        available: trip.seats - trip.reserved,
        actions: <Actions trip={trip} />,
      };
    }),
  };

  const tripFiltered3 = {
    columns: tripData.columns,
    rows: filterPast.map((trip) => {
      return {
        from: trip.from,
        to: trip.to,
        departure: trip.departure,
        arrival: trip.arrival,
        journeyDate: moment(trip.journeyDate).format("MM-DD-YYYY"),
        seats: trip.seats,
        status:
          trip.status === "Fully booked" || trip.status === 'Plein' ? (
            <div className="badge badge-soft-warning font-size-12">
              {t("fully_booked")}
            </div>
          ) :
            moment(new Date()).format("MM-DD-YYYY") >
              trip.journeyDate ? (
              <div className="badge badge-soft-danger font-size-12">
                {t("past")}
              </div>
            ) : (
              <div className="badge-soft-success font-size-12">

                {t("yet_to_start")}
              </div>
            ),
        tripType: trip.tripType,
        price: trip.price,
        reserved: trip.reserved,
        available: trip.seats - trip.reserved,
        actions: <Actions trip={trip} />,
      };
    }),
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={t('Trips')} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div>
                    <Link
                      to="#"
                      onClick={() => {
                        showModal();
                      }}
                      className="btn btn-primary mb-2"
                    >
                      <i className="mdi mdi-plus mr-2"></i> {t('Add Trip')}
                    </Link>
                  </div>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                     {/*} <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: tab.activeTabJustify === "1",
                          })}
                          onClick={() => {
                            toggleCustomJustified("1");
                          }}
                        >
                          <span className="d-none d-sm-block">{t('all_trips')}</span>
                        </NavLink>
                      </NavItem>*/}
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: tab.activeTabJustify === "1",
                          })}
                          onClick={() => {
                            toggleCustomJustified("1");
                          }}
                        >
                          <span className="d-none d-sm-block">{t('trips_today')}</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: tab.activeTabJustify === "2",
                          })}
                          onClick={() => {
                            toggleCustomJustified("2");
                          }}
                        >
                          <span className="d-none d-sm-block">{t('upcoming')}</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: tab.activeTabJustify === "3",
                          })}
                          onClick={() => {
                            toggleCustomJustified("3");
                          }}
                        >
                          <span className="d-none d-sm-block">{t('past')}</span>
                        </NavLink>
                      </NavItem>
                     
                     
                    </Nav>

                    <TabContent activeTab={tab.activeTabJustify}>
                      {/*<TabPane tabId="1" className="p-3">
                        <MDBDataTable
                          noBottomColumns
                          responsive
                          data={tripData}
                          className="mt-4"
                        />
                      </TabPane>*/}
                      <TabPane tabId="1" className="p-3">
                        <MDBDataTable
                          responsive
                          data={tripFiltered1}
                          className="mt-4"
                        />
                      </TabPane>
                      <TabPane tabId="2" className="p-3">
                        <MDBDataTable
                          responsive
                          data={tripFiltered2}
                          className="mt-4"
                        />
                      </TabPane>
                      <TabPane tabId="3" className="p-3">
                        <MDBDataTable
                          noBottomColumns
                          responsive
                          data={tripFiltered3}
                          className="mt-4"
                        />
                      </TabPane>
                     
                      
                    </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <TripModal
            getModal={setModal}
            modal={modal}
            trip={trip_blank}
            title={t("Add Trip")}
            fetchTrips={fetchTrips}
            
            buttonText={t("Add")}
            controlText={'Add'}
          />

          <TripEditModal
            getModal={setEditModal}
            modal={editModal}
            trip={trip}
            title={t("Edit Trip")}
            fetchTrips={fetchTrips}
           
            buttonText={t("Edit")}
          />

          <DeleteConfirmation
            showModal={displayConfirmationModal}
            confirmModal={deleteTrip}
            hideModal={hideConfirmationModal}
            message={deleteMessage}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default  withNamespaces() (Trips);
