import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { updateAction, updateBooking } from "../../../utils/updateActions";
import moment from "moment";
import { MDBDataTable } from "mdbreact";


import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import EditBookingModal from "../../../components/Modals/EditBookingModal";

import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";
import { withNamespaces} from 'react-i18next';

function TripBookings({t}) {
  const [loading, setLoading] = useState(true);
  const {actions, state } = useStateMachine({ updateAction, updateBooking });
  const [booking, setBooking] = useState({ booking: null });
 
  const [breadcrumb] = useState([
    { title: t('Agency'), link: "#" },
    { title: t('booked_tickets'), link: "#" },
  ]);

  const [editModal, setEditModal] = useState({
    modal_static: false,
  });

  const showEditModal = () => {
   
    setEditModal({ modal_static: true });
 
  };

  const bookingRef = useRef({});
  const getCurrentBooking = (b) => {
    setBooking({ booking: b});
    bookingRef.current = b;
   
    actions.updateBooking({ booking: bookingRef.current });
    console.log(state);
   
  };


  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchBookingsByTrip = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/bookings/trip/${state.trip.trip.id}`
        );
  
        const values = Object.values(data);
        setData(values[0]);
        toast.success(t('success'))
        setLoading(false);
      } catch (err) {

        setLoading(false);
        toast.error(t('failed'));
       
        
      }
    };
  
    fetchBookingsByTrip();
  }, [state.trip.trip.id, t]);

  const fetchTrip = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/bookings/trip/${state.trip.trip.id}`
      );

      const values = Object.values(data);
      setData(values[0]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
     
      
    }
  };


  const Actions = ({ booking }) => {
    return (
      <>
        <Link
          to="#"
          className="mr-3 text-primary"
          id=""
          onClick={() => { getCurrentBooking(booking); showEditModal()}}
        >
          <i className="mdi mdi-pencil font-size-18"></i>
        </Link>
       
        <Link
          to="#"
          className="mr-3 text-primary"
          id=""
          onClick={() => {getCurrentBooking(booking); showEditModal()}}
        >
          <i className="mdi mdi-print font-size-18"></i>
         
        </Link>
       
      </>
    );
  };

  const tripData = {
    columns: [
      {
        label: t('ticket_number'),
        field: "ticket",
      },
      {
        label: t('name'),
        field: "name",
      },
      {
        label: t('id_card'),
        field: "idcard",
      },
     
      {
        label: t('to'),
        field: "to",
      },
      {
        label: t('departure_time'),
        field: "departure",
      },

      {
        label: t('journey_date'),
        field: "journeyDate",
      },
      {
        label: t('seat_no'),
        field: "seat",
      },
      {
        label: t('status'),
        field: "status",
      },

      {
        label: t('fare'),
        field: "price",
      },
      {
        label: t('booking_fee'),
        field: "bookingFee",
      },

      {
        label: t('actions'),
        field: "actions",
      },
      
    ],
    rows: data.map((trip) => {
      return {
        ticket: trip.ticketNumber,
        name: trip.name,
        idcard: trip.idCardNo,
       
        
        to: trip.arrivalCity,
        departure: trip.departureTime,

        journeyDate: moment(trip.journeyDate).format("MM-DD-YYYY"),
        seat: trip.seat,
        status: trip.status === "Pending"? t('pending'): t('paid'),
        price: trip.price,
        bookingFee: trip.booking_fee, 

       actions: <Actions booking={trip} />
      };
    }),
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
            <Breadcrumbs title={t('Bookings')} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pt-0">
                  <Nav tabs className="nav-tabs-custom mb-4"></Nav>
                  <MDBDataTable noBottomColumns responsive data={tripData} className="mt-4" />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <EditBookingModal
            getEditModal={setEditModal}
            editModal={editModal}
           
            title={t("sell_ticket")}
            fetchTrips={fetchTrip}
            booking={booking}
            buttonText={t("save")}
            controlText={'Edit'}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default  withNamespaces() (TripBookings);
