import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { updateUser } from "../../../utils/updateActions";
import { toast } from "react-hot-toast";

import { withNamespaces } from "react-i18next";

function ProfileMenu(props) {
  const history = useHistory();
  const {actions, state } = useStateMachine({ updateUser });
  const [open, setOpen] = useState(false);

  function toggle() {
    open ? setOpen(false) : setOpen(true);
  }

  async function logOut() {
    const data = localStorage.getItem(props.storageKey);
    try {
      await axios.post(`/api/v1/admin-logout`, JSON.parse(data));
      localStorage.removeItem('authUser');
      let blank = {}
      actions.updateUser(blank)
      history.push('/')
    
      
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  async function changePassword() {
    const data = localStorage.getItem('authUser');
    try {
      await axios.post(`${`/api/v1/admin-logout`}`, JSON.parse(data));
      localStorage.removeItem('authUser');

      history.push('/admin-forgot');
      let blank = {}
      actions.updateUser(blank)
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

   function editProfile() {
    props.setModal({ modal_static: true });
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={open}
        toggle={toggle}
        className="d-inline-block user-dropdown"
      >
         {state?.user?.data?.data?.permissions === "CRUD_admins"? (
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
           <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
        </DropdownToggle>):(null)}
          {state?.user?.data?.data?.permissions === "CRUD_admins"?   null : (
        <DropdownToggle
          tag="button"
          className={props.source === "user" ? ("btn header-item2 waves-effect"):("btn header-item waves-effect")}
          id="page-header-user-dropdown"
        >
        {props.source === "user" ? ( <>
            
          
            <span className="d-none d-xl-inline-block ml-1 text-transform">
              {state?.user?.data?.data?.email}
            </span>
          </>
        ):  <>
            
        <img
          className="rounded-circle header-profile-user mr-1"
          src={state?.user?.data?.data?.agencyLogo}
          alt="Header Avatar"
        />
        <span className="d-none d-xl-inline-block ml-1 text-transform">
          {state?.user?.data?.data?.agencyName}
        </span>
      </>
    }
           
         
          <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
        </DropdownToggle>)}
      
        <DropdownMenu right>
          {props.source === 'user' ? (  <>
            <DropdownItem divider />
           <DropdownItem
           className=""
           href=""
           onClick={editProfile}
         >
           <i className="ri-edit-box-line align-middle mr-1"></i>{" "}
           {props.t("edit_profile")}
         </DropdownItem></>
          ):(null)}
          <DropdownItem divider />
          <DropdownItem
            className=""
            href=""
            onClick={changePassword}
          >
            <i className="ri-edit-circle-line align-middle mr-1"></i>{" "}
            {props.t("reset_password")}
          </DropdownItem>

          <DropdownItem divider />
          <DropdownItem className="" href="" onClick={logOut}>
            <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
            {props.t("Logout")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

export default withNamespaces()(ProfileMenu);
