import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,

} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import { MDBDataTable } from "mdbreact";
//import "./datatables.scss";
import { useStateMachine } from "little-state-machine";
import { updateParcel } from "../../../utils/updateActions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import DeleteConfirmation from "../../../components/Modals/DeleteConfirmation";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";
import { withNamespaces } from "react-i18next";

import ParcelModal from "../../../components/Modals/ParcelModal";

function RegisterParcel({ t }) {
  document.body.setAttribute('style', 'background: #f8f8f8!important;')
 
  const [loading, setLoading] = useState(true);

  const [modal, setModal] = useState({
    modal_static: false,
  });
  const [editModal, setEditModal] = useState({
    modal_static: false,
  });

  const [deleteMessage, setDeleteMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const { actions, state } = useStateMachine({ updateParcel });

 
  const [breadcrumb] = useState([
    { title: state?.user?.data?.data?.agencyName, link: "#" },
    { title: t('parcels'), link: "#" },
  ]);

  const [data, setData] = useState([]);
  const parcelRef = useRef({});

  const [tab, setTab] = useState({ activeTabJustify: "1" });

  function toggleCustomJustified(currentTab) {
    if (tab.activeTabJustify !== currentTab) {
      setTab({
        activeTabJustify: currentTab,
      });
    }
  }

 
  const showModal = () => {
    
    setModal({ modal_static: true });

  };

  const showEditModal = () => {
    setEditModal({ modal_static: true });

  };

  const showDeleteModal = () => {
    setDeleteMessage(
      `${t('delete_confirm')} ?`
    );
    setDisplayConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

 

 
  const fetchParcels = async () => {
    try {
      const { data } = await axios.get(`api/v1/parcels/from-location`);

      const values = Object.values(data);
      setData(values[0]);

      setLoading(false);
      toast.success(data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);

    }
  };

  useEffect(() => {
    const getParcels = async () => {
      console.log()
      try {
        const { data } = await axios.get(`api/v1/parcels/from-location`);
  
        const values = Object.values(data);
        setData(values[0]);
  
        setLoading(false);
        toast.success(data.message);
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
  
      }
    };
    
    getParcels();
  }, [state.user.data.data.agencyId, t]);

  const filterSentToday = data.filter((parcel) => {
    return (
      moment(parcel.receievedDate).format('DD-MM-YYYY') ===
      moment(new Date()).format("DD-MM-YYYY")
    );
  });



  const getCurrentparcel = (parcel) => {
    parcelRef.current = parcel;
    actions.updateParcel({ parcel: parcelRef.current });
  };

  const deleteParcel = async (formData) => {
    try {
      const { data } = await axios.delete(`/api/v1/parcels/${parcelRef.current.id}`, formData);
      if (data?.error) {
        toast.error(data.error);
      } else {
        fetchParcels();
        setModal({ modal_static: false });

        setDisplayConfirmationModal(false);
        toast.success(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

 



  const Actions = ({ parcel }) => {
    return (
     
      <>
        <Link
          to="#"
          className="mr-3 text-primary"
          id="edit1"
          onClick={() => {
             getCurrentparcel(parcel);
            
             showEditModal();
          }}
        >
          <i className="mdi mdi-pencil font-size-18"></i>
        </Link>
        
        <Link
          to="#"
          className="mr-3 text-danger"
          id="delete1"
          onClick={() => {
            getCurrentparcel(parcel);
            showDeleteModal();
          }}
        >
          <i className="mdi mdi-trash-can font-size-18"></i>
        </Link>
        
      </>
    );
  };


  const tableColumns = [
    {
      label: t("ticket_number"),
      field: "parcel",
    },
   
    {
      label: t("to"),
      field: "to",
    },
    {
      label: t("received_date"),
      field: "receivedDate",
    },
    
    {
      label: t("sender_name"),
      field: "senderName",
    },
    {
      label: t("sender_phone"),
      field: "senderPhone",
    },
    {
      label: t("receiver_name"),
      field: "receiverName",
    },
    {
      label: t("receiver_phone"),
      field: "receiverPhone",
    },

    {
      label: t("parcel_type"),
      field: "parcelType",
    },

    {
      label: t("price"),
      field: "price",
    },
    {
      label: t("status"),
      field: "status",
    },


    {
      label: t("action"),
      field: "actions",
    },
  ];

  const allParcels = {
    columns: tableColumns,
    rows: data.map((parcel) => {
      return {
        parcel: parcel.parcelNumber,
        to: parcel.to,
        receivedDate: moment(parcel.receievedDate).format('DD-MM-YYYY'),
       // arrivalDate: moment(parcel.arrivalDate).format('DD-MM-YYYY'),
        senderName: parcel.senderName,
        senderPhone: parcel.senderPhone,
        receiverName: parcel.receiverName,
        receiverPhone: parcel.receiverPhone,
        //vehicleNumber: parcel.vehicleNumber,
        price: parcel.price,
        parcelType: parcel.parcelType,

        status:
          parcel.status === "Collected" ? (
            <div className="badge badge-soft-warning font-size-12">
              {t("collected")}
            </div>
          ) : (
            <div className="badge badge-soft-success font-size-12">
              {t("received")}
            </div>
          ),
        actions: <Actions parcel={parcel} />,
      };
    }),
  };

  const parcelData = {
    columns: tableColumns,
    rows: filterSentToday.map((parcel) => {
      return {
        parcel: parcel.parcelNumber,
        to: parcel.to,
        receivedDate: moment(parcel.receievedDate).format('DD-MM-YYYY'),
       // arrivalDate: moment(parcel.arrivalDate).format('DD-MM-YYYY'),
        senderName: parcel.senderName,
        senderPhone: parcel.senderPhone,
        receiverName: parcel.receiverName,
        receiverPhone: parcel.receiverPhone,
        //vehicleNumber: parcel.vehicleNumber,
        price: parcel.price,
        parcelType: parcel.parcelType,

        status:
          parcel.status === "Collected" ? (
            <div className="badge badge-soft-warning font-size-12">
              {t("collected")}
            </div>
          ) : (
            <div className="badge badge-soft-success font-size-12">
              {t("received")}
            </div>
          ),
        actions: <Actions parcel={parcel} />,
      };
    }),
  };





  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={t('parcels')} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pt-3">
                  <div>
                    <Link
                      to="#"
                      onClick={() => {
                        showModal();
                      }}
                      className="btn btn-primary mb-2"
                    >
                      <i className="mdi mdi-plus mr-2"></i> {t('register_parcel')}
                    </Link>
                  </div>
                  <Nav tabs className="nav-tabs-custom nav-start">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: tab.activeTabJustify === "1",
                        })}
                        onClick={() => {
                          toggleCustomJustified("1");
                        }}
                      >
                        <span className="d-none d-sm-block">{t('received_today')}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: tab.activeTabJustify === "2",
                        })}
                        onClick={() => {
                          toggleCustomJustified("2");
                        }}
                      >
                        <span className="d-none d-sm-block">{t('received_earlier')}</span>
                      </NavLink>
                    </NavItem>

                  </Nav>
                  <TabContent activeTab={tab.activeTabJustify}>
                  <TabPane tabId="1" className="p-3">
                    <MDBDataTable
                   noBottomColumns
                    responsive
                    data={parcelData}
                    className="mt-4"
                  />
                    </TabPane>
                  <TabPane tabId="2" className="p-3">
                      <MDBDataTable
                       noBottomColumns
                        responsive
                        data={allParcels}
                        className="mt-4"
                      />
                    </TabPane>
                   
                  </TabContent>

                  
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ParcelModal
            getModal={setModal}
            modal={modal}

            title={t("register_parcel")}
            fetchParcels={fetchParcels}

            buttonText={t("Add")}
            controlText={'Add'}
          />
           <ParcelModal
            getModal={setEditModal}
            modal={editModal}
            parcel={parcelRef.current}
            title={t("edit_parcel")}
            fetchParcels={fetchParcels}

            buttonText={t("Edit")}
            controlText={'Edit'}
          />
           <DeleteConfirmation
            showModal={displayConfirmationModal}
            confirmModal={deleteParcel}
            hideModal={hideConfirmationModal}
            message={deleteMessage}
          /> 
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(RegisterParcel);
