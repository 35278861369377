import React, { useState, useEffect } from "react";
//import { useStateMachine } from "little-state-machine";
//import { updateUser, updateBookings } from "../../../../utils/updateActions";

import axios from "axios";
import moment from "moment";

import { Card, CardBody, Media } from "reactstrap";

import { withNamespaces } from "react-i18next";

const DailySummary = ({ t }) => {
 // const { state } = useStateMachine(updateUser, updateBookings);
 
  const [data, setData] = useState([]);
 
  let dayTotal = 0;
 
  const [icons] = useState({
    reports: [
      { icon: "ri-stack-line", title: t('today') },
      { icon: "ri-store-2-line", title: t('sales_today') },
      { icon: "ri-briefcase-4-line", title: t('parcels') },
    ],
  });
  const fetchBookingsByAgency = async () => {

    try {
      const { data } = await axios.get(`/api/v1/bookings`);

      const values = Object.values(data);
      setData(values[0]);
    


    } catch (err) { }
  };

  useEffect(() => {
    fetchBookingsByAgency();
    
  }, []);
  getDaySalesTotal();
  function getDaySalesTotal() {
  
    const filterDay = data.filter((booking) => {
    
      return (
        booking.journeyDate ===
        moment(new Date()).format("MM-DD-YYYY")
      );
    });

    for (let i = filterDay.length - 1; i >= 0; i--) {


      let total = filterDay[i].price;

      dayTotal += total;

    }


  }



  return (

    <Card>
      <CardBody>
        <Media>
          <Media body className="overflow-hidden">
            <p className="text-truncate font-size-14 mb-2">
              {icons.reports[1].title}
            </p>
            <h4 className="mb-0">{dayTotal}</h4>
          </Media>
          <div className="text-primary">
            <i className={icons.reports[1].icon + " font-size-24"}></i>
          </div>
        </Media>
      </CardBody>
    </Card>
  );
};
export default withNamespaces()(DailySummary);
