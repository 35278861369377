import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import ParcelChart from "./ParcelChart";
import { withNamespaces } from "react-i18next";
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import BookingChart from "./BookingChart";
import Summary from "./ParcelSummary";
import BookingSummary from "./BookingSummary";


function OwnerDashboard ({t}) {
 
  const [breadcrumbItems] = useState([
    { title: t('owner'), link: "#" },
    
    { title: t("Dashboard"), link: "#" },


  ]);
  
    document.body.setAttribute('style', 'background: #f8f8f8!important;')
 
    return (
      <React.Fragment>
        <div className="page-content">
          <Container>

            <Breadcrumbs title={t('Dashboard')} breadcrumbItems={breadcrumbItems} />
            <Row>
             

              <Col xl={4}>
                <Card>
                  <CardBody>
                    <Media>
                      <Media body className="overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">{t('users')}</p>
                        <h4 className="mb-0">44756</h4>
                      </Media>

                    </Media>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
               <Summary/>
              </Col>

              <Col xl={4}>
               <BookingSummary/>
              </Col>
             
            </Row>

            <Row className="">
              <Col xl={12}>

                <Card>
                  <CardBody>
                    <BookingChart />
                  </CardBody>
                </Card>

              </Col>
              <Col xl={12}>

                <Card>
                  <CardBody>
                    <ParcelChart />
                  </CardBody>
                </Card>

              </Col>


            </Row>

            <Row>



            </Row>

          </Container>
        </div>
      </React.Fragment>
    );
  }


export default withNamespaces()(OwnerDashboard);
