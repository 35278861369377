import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { toast } from "react-hot-toast";
import React, {useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useStateMachine } from "little-state-machine";
import { updateBooking, updateAction } from "../../utils/updateActions";
import { customStyles } from "../../utils/styles";
import { FadeLoader } from "react-spinners";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";



function EditBookingModal({
  editModal,
  getEditModal,
  title,
  booking,

  fetchTrips,
  t,
  buttonText,
}) {
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { state } = useStateMachine({ updateBooking, updateAction });
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const status = [
    { label: t('pending'), value: t('pending') },
    { label: t('paid'), value: t('paid') },
  ];

  useEffect(() => {
    reset({
      data: booking,
    });
  }, [booking, reset]);

  const handleStatusChange = e => {
    setSelectedStatus(e.value);
  }

  const updateInfo = async (formData) => {
    setLoading(true);
    formData.status = selectedStatus;
    const {data,...rest} = formData;
    try {
      const { data1 } = await axios.put(`api/v1/bookings/${state.booking.booking.id}`, rest);
      if (data1?.error) {
        toast.error(data1.message);
      } else {
        setLoading(false);
        fetchTrips();
        getEditModal({ modal_static: false });
    
      }
    } catch (error) {


      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal isOpen={editModal.modal_static} backdrop="static" centered size="lg">
      <ModalHeader toggle={() => getEditModal({ modal_static: false })}>
        {title}
      </ModalHeader>
      <ModalBody>
        <form
         onSubmit={handleSubmit(updateInfo)}
        >
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="name">{t('name')}</label>
                <input

                  type="text"
                  name="name"
                  id="name"
                  defaultValue={booking?.booking?.name}

                  className="form-control"
                  {...register("name", {
                    required: true,
                    minLength: 2
                  })}
                />
              </div>
              {errors.name && <p style={{ color: 'red' }}>{t('agency_branch_err')}</p>}
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="idCardNo">{t('id_card')}</label>
                <input

                  type="text"
                  name="idCardNo"
                  id="idCardNo"
                  defaultValue={booking?.booking?.idCardNo}
                  className="form-control"
                  {...register("idCardNo", {
                    required: true,
                   
                  })}
                />
              </div>
              {errors.idCardNo && <p style={{ color: 'red' }}>{t('id_card_err')}</p>}
            </Col>
            {/* <Col lg={6}>
              <div className="form-group">
                <label htmlFor="number_of_seats">{t('number_of_seats')}</label>
                <input
                  defaultValue={""}
                  type="number"
                  name="number_of_seats"
                  id="number_of_seats"
                  className="form-control"
                  {...register("numberOfSeats", {
                    required: true,

                  })}
                />
              </div>
              {errors.idCardNo && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>*/}

            {/* <Col lg={6}>
              <div className="form-group">
                <label htmlFor="status">{t('status')}</label>
                <input
                  className="form-control"
                  defaultValue={booking?.booking?.status}
                  type="text"
                  name="status"
                  {...register("status", {
                    required: true,
                  })}
                >
                 
                  
                </input>
              </div>
              </Col>*/}

            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="status">{t('status')}</label>
                <Controller
                  control={control}
                  name="status"
                  render={() => (
                    <Select
                      styles={customStyles}
                      value={status.find(obj => obj.value === selectedStatus)}
                      defaultValue={booking?.booking?.status}
                      placeholder={t('status')}
                      options={status} // set list of the data
                      onChange={handleStatusChange} // assign onChange function
                    />
                  )}
                />
              
              </div>
              {errors.status && <p style={{ color: 'red' }}>{t('required_field')}</p>}
            </Col>
              
          </Row>

          <ModalFooter>
            <Button

              color="light"
              onClick={() => getEditModal({ modal_static: false })}
            >
              {t('cancel')}
            </Button>
            {loading ? (<FadeLoader color="BCC015" />) : (<Button type="submit" color="primary">
              {buttonText}
            </Button>)}
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default withNamespaces()(EditBookingModal);
