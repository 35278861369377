import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
//import "./datatables.scss";

import axios from "axios";
import { withNamespaces } from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteConfirmation from "../../../components/Modals/DeleteConfirmation";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-hot-toast";
import PersonnelModal from "../../../components/Modals/PersonnelModal";
import { useStateMachine } from "little-state-machine";

function Personnel({t}) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const {state} = useStateMachine();
  const [breadcrumb] = useState([
    { title: state?.user?.data?.data?.agencyName, link: "#" },
    { title: t('Personnel'), link: "#" },
  ]);

 
  const [modal, setModal] = useState({
    modal_static: false,
  });
  const [editModal, setEditModal] = useState({
    modal_static: false,
  });


  const [personnel, setPersonnel] = useState({ personnel: null });
  const personnelRef = useRef({});

  const [deleteMessage, setDeleteMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  useEffect(() => {
    fetchPersonnel();
  }, []);

  const fetchPersonnel = async () => {
    try {
      const { data } = await axios.get("api/v1/Personnel");

      const values = Object.values(data);
      setData(values[0]);
    
      toast.success(data.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    
    }
  };

  const showModal = () => {
    const personnel = {};

    getCurrentPersonnel(personnel);
    setModal({ modal_static: true });
   
  };

  const showEditModal = () => {
    setEditModal({ modal_static: true });
   
  };

  const showDeleteModal = () => {
    setDisplayConfirmationModal(true);
  };

  const getCurrentPersonnel = async (personnel) => {
    setPersonnel(personnel);
    personnelRef.current = personnel;

    setDeleteMessage(
      `${t('delete_personnel')} '${personnelRef.current.name}'?`
    );
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const deletepersonnel = async (formData) => {
    try {
      const { data } = await axios.delete(
        `api/v1/Personnel/${personnel.id}`,
        formData
      );
      if (data?.error) {
        toast.error(t('failed'));
      } else {
        fetchPersonnel();
        setModal({ modal_static: false });

        setDisplayConfirmationModal(false);
        toast.success(t('success'));
      }
    } catch (err) {
     

      toast.error(t('failed'));
    }
  };

  const personnelData = {
    columns: [
      {
        label: t('name'),
        field: "name",
      },
      {
        label: t('surname'),
        field: "surname",
      },
      {
        label: t('age'),
        field: "age",
      },
      {
        label: t('sex'),
        field: "sex",
      },
      {
        label: t('email_address'),
        field: "email",
      },
      {
        label: t('phone'),
        field: "phone",
      },
      {
        label: t('id_card'),
        field: "id",
      },
      {
        label: t('location'),
        field: "location",
      },
      /*{
        label: t('expiry_date'),
        field: "expires",
      },
       {
        label: t('drivers_licence'),
        field: "licence",
      },

      {
        label: t('expiry_date'),
        field: "licenceExp",
      },*/
      {
        label: t('personnel_type'),
        field: "personnel",
      },
      {
        label: t('action'),
        field: "actions",
      },
    ],
    rows: data.map((personnel) => {
      return {
        name: personnel.name,
        surname: personnel.surname,
        age: personnel.age,
        sex: personnel.sex,
        email: personnel.email,
        phone: personnel.phoneNumber,
        id: personnel.idCardNumber,
        location: personnel.location,
        //expires: personnel.idValidTill,
        personnel: personnel.personnelType,
        //licence: personnel.driversLicenceNo,
        //licenceExp: personnel.dlValidTill,
        actions:  (
          <td>
            <Link
              to="#"
              className="mr-3 text-primary"
              id={"edit" + personnel.id}
              onClick={() => {
                getCurrentPersonnel(personnel);

                showEditModal();
              }}
            >
              <i className="mdi mdi-pencil font-size-18"></i>
            </Link>
          
            
            <Link
              to="#"
              className="text-danger"
              id={"delete" + personnel.id}
              onClick={() => {
                getCurrentPersonnel(personnel);

                showDeleteModal();
              }}
            >
              <i className="mdi mdi-trash-can font-size-18"></i>
            </Link>
            
            
           
          </td>
        ),
      };
    }),
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ScaleLoader color="#bcc014" size="80px" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={t('Personnel')} breadcrumbItems={breadcrumb} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div>
                    <Link
                      to="#"
                      onClick={() => {
                        showModal();
                      }}
                      className="btn btn-primary mb-2"
                    >
                      <i className="mdi mdi-plus mr-2"></i> {t('Add Personnel')}
                    </Link>
                  </div>
                  <MDBDataTable
                    noBottomColumns
                    responsive
                    data={personnelData}
                    className="mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <PersonnelModal
            getModal={setModal}
            modal={modal}
            personnel={personnel}
            title={t("Add Personnel")}
            fetchPersonnel={fetchPersonnel}
            
            buttonText={t("Add")}
            controlText={'Add'}
          />

          <PersonnelModal
            getModal={setEditModal}
            modal={editModal}
            personnel={personnel}
            title={t("Edit Personnel")}
            fetchPersonnel={fetchPersonnel}
          
            buttonText={t("save")}
          />

          <DeleteConfirmation
            showModal={displayConfirmationModal}
            confirmModal={deletepersonnel}
            hideModal={hideConfirmationModal}
            message={deleteMessage}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}
export default  withNamespaces() (Personnel);