import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-GB";
import ptBR from "date-fns/locale/pt-BR";
import { withNamespaces } from "react-i18next";
import React, { useState } from "react";
import { useStateMachine } from "little-state-machine";
import { customStyles } from "../../utils/styles";
import Select from "react-select";
import { updateUser } from "../../utils/updateActions";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";
import { toast } from "react-hot-toast";
import moment from "moment";

const key = localStorage.getItem('i18nextLng');

let towns = [
  { label: "Bafoussam", value: "Bafoussam" },
  { label: "Bamenda", value: "Bamenda" },

  { label: "Buea", value: "Buea" },
  { label: "Douala", value: "Douala" },
  { label: "Dschang", value: "Dschang" },
  { label: "Edea", value: "Edea" },
  { label: "Garoua", value: "Garoua" },
  { label: "Kribi", value: "kribi" },
  { label: "Kumba", value: "Kumba" },
  { label: "Limbe", value: "Limbe" },
  { label: "Maroua", value: "Maroua" },
  { label: "Nkongsamba", value: "Nkongsamba" },
  { label: "Yaounde", value: "Yaounde" },
];

let tripTypes = [{ label: "Regular", value: "Regular" }, { label: "VIP", value: "VIP" },]

function TripModal({
  modal,
  getModal,
  title,
  trip,
  fetchTrips,

  buttonText,
  t,
}) {

  // const { from, to, departure, arrival, journeyDate, seats, status, price } =
  // trip;


  const { state } = useStateMachine(updateUser);
  const [toCity, setToCity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const [date, setDate] = useState({ default_date: new Date() });
  const [startDate, setStartDate] = useState(new Date());
  const [depDate, setDepDate] = useState(new Date());
  const [arrDate, setArrDate] = useState(new Date());
  const [boardingTime, setBoardingTime] = useState(new Date());

  const [values, setValues] = useState({
    from: selectedOption?.value,
    to: toCity,
    departure: moment(depDate, 'hh:mm A').format('HH:mm a'),
    arrival: moment(arrDate, 'hh:mm A').format('HH:mm a'),

    oDeparture: depDate,
    oArrival: arrDate,
    journeyDate: startDate,
    seats: "",
    status: "",
    price: "",
   
    boarding_time: boardingTime,
    tripType: type,
  });

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };


  const addMonth = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = {
      from: selectedOption?.value,
      to: toCity?.value,
      departure: moment(depDate, 'hh:mm A').format('HH:mm a'),
      arrival: moment(arrDate, 'hh:mm A').format('HH:mm a'),
      oDeparture: depDate,
      oArrival: arrDate,
      journeyDate: startDate,
      seats: values.seats,
      price: values.price,
     
      boarding_time: boardingTime,
      tripType: type?.value,
    }
    if (state.user.data.data.permissions === "CRUD_teller") {
      try {
        const { data } = await axios.post("api/v1/trips/teller-trip-create-month", formData);
        if (data?.error) {
          toast.error(data.message);
          setLoading(false);
        } else {
          getModal({ modal_static: false });
          fetchTrips();
          toast.success(data.message);
          setLoading(false);
        }
      } catch (error) {
        setDate(new Date());
        toast.error(error.response.data.message);
        setLoading(false);
      }
    } else {
      try {
        const { data } = await axios.post("api/v1/trips/trip-create-month", formData);
        if (data?.error) {
          toast.error(data.message);
          setLoading(false);
        } else {
          getModal({ modal_static: false });
          fetchTrips();
          toast.success(data.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(date);
        toast.error(error.response.data.message);
        setLoading(false);
      }
    }
  }

  const addNextDay = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = {
      from: selectedOption?.value,
      to: toCity?.value,
      departure: moment(depDate, 'hh:mm A').format('HH:mm a'),
      arrival: moment(arrDate, 'hh:mm A').format('HH:mm a'),
      oDeparture: depDate,
      oArrival: arrDate,
      journeyDate: startDate,
      seats: values.seats,
      price: values.price,
     
      boarding_time: boardingTime,
      tripType: type?.value,
    }

    if (state.user.data.data.permissions === "CRUD_teller") {
      try {
        const { data } = await axios.post("api/v1/trips/teller-trip-create-next-day", formData);
        if (data?.error) {
          toast.error(data.message);
          setLoading(false);
        } else {
          getModal({ modal_static: false });
          fetchTrips();
          toast.success(data.message);
          setLoading(false);
        }
      } catch (error) {
        setDate(new Date());
        toast.error(error.response.data.message);
        setLoading(false);
      }
    } else {
      try {
        const { data } = await axios.post("api/v1/trips/trip-create-next-day", formData);
        if (data?.error) {
          toast.error(data.message);
          setLoading(false);
        } else {
          getModal({ modal_static: false });
          fetchTrips();
          toast.success(data.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(date);
        toast.error(error.response.data.message);
        setLoading(false);
      }
    }
  }

  const addWeek = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = {
      from: selectedOption?.value,
      to: toCity?.value,
      departure: moment(depDate, 'hh:mm A').format('HH:mm a'),
      arrival: moment(arrDate, 'hh:mm A').format('HH:mm a'),
      oDeparture: depDate,
      oArrival: arrDate,
      journeyDate: startDate,
      seats: values.seats,
      price: values.price,
     
      boarding_time: boardingTime,
      tripType: type?.value,
    }

    if (state.user.data.data.permissions === "CRUD_teller") {
      try {
        const { data } = await axios.post("api/v1/trips/teller-trip-create-one-week", formData);
        if (data?.error) {
          toast.error(data.message);
          setLoading(false);
        } else {
          getModal({ modal_static: false });
          fetchTrips();
          toast.success(data.message);
          setLoading(false);
        }
      } catch (error) {
        setDate(new Date());
        toast.error(error.response.data.message);
        setLoading(false);
      }
    } else {
      try {
        const { data } = await axios.post("api/v1/trips/trip-create-one-week", formData);
        if (data?.error) {
          toast.error(data.message);
          setLoading(false);
        } else {
          getModal({ modal_static: false });
          fetchTrips();
          toast.success(data.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(date);
        toast.error(error.response.data.message);
        setLoading(false);
      }
    }
  }

 
  const addTrip = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = {
      from: selectedOption.value,
      to: toCity.value,
      departure: moment(depDate, 'hh:mm A').format('HH:mm a'),
      arrival: moment(arrDate, 'hh:mm A').format('HH:mm a'),
      oDeparture: depDate,
      oArrival: arrDate,
      journeyDate: startDate,
      seats: values.seats,
      price: values.price,
     
      boarding_time: boardingTime,
      tripType: type.value,
    }
    console.log(formData.oDeparture);

    if (state.user.data.data.permissions === "CRUD_teller") {
      try {
        const { data } = await axios.post("api/v1/trips", formData);
        if (data?.error) {
          toast.error(data.message);
          setLoading(false);
        } else {
          getModal({ modal_static: false });
          fetchTrips();
          toast.success(data.message);
          setLoading(false);
        }
      } catch (error) {
        setDate(new Date());
        toast.error(error.response.data.message);
        setLoading(false);
      }
    } else {
      try {
        const { data } = await axios.post("api/v1/trips/trip-create", formData);
        if (data?.error) {
          toast.error(data.message);
          setLoading(false);
        } else {
          getModal({ modal_static: false });
          fetchTrips();
          toast.success(data.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(date);
        toast.error(error.response.data.message);
        setLoading(false);
      }
    }
  };



  return (
    <Modal isOpen={modal.modal_static} backdrop="static" centered size="lg">
      <ModalHeader toggle={() => getModal({ modal_static: false })}>
        {title}
      </ModalHeader>
      <ModalBody>
        <form>
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="from">{t("from")}</label>

                <Select
                  options={towns}

                  defaultValue={''}
                  onChange={setSelectedOption}


                  styles={customStyles}
                  isSearchable
                  name="from"
                  placeholder={t("from")}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="to">{t("to")}</label>
                <Select
                  options={towns}
                  onChange={setToCity}


                  defaultValue={''}
                  styles={customStyles}
                  isSearchable
                  name="to"
                  placeholder={t("to")}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="departure">{t("departure")}</label>

                <DatePicker
                  selected={depDate}
                  onChange={(d) => setDepDate(d)}
                  className="form-control"

                  placeholderText={t("time")}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  locale={ptBR}
                  dateFormat="p"
                  timeFormat="p"
                  type="time"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="arrival">{t("arrival")}</label>

                <DatePicker
                  selected={arrDate}
                  onChange={(date) => setArrDate(date)}
                  className="form-control"

                  placeholderText={t("time")}
                  showTimeSelect
                  showTimeSelectOnly

                  timeIntervals={30}
                  timeCaption="Time"
                  locale={ptBR}
                  dateFormat="p"
                  timeFormat="p"

                  type="time"
                />
              </div>
            </Col>
            {key === 'fr' ? <Col lg={6}>
              <div className="form-group">
                <label htmlFor="journeyDate">{t("journey_date")}</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={moment().toDate()}
                  placeholderText={t("select_date")}
                  type="date"
                  locale={fr}
                  defaultValue={''}
                  className="form-control"
                />
              </div>
            </Col> : <Col lg={6}>
              <div className="form-group">
                <label htmlFor="journeyDate">{t("journey_date")}</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={moment().toDate()}
                  placeholderText={t("select_date")}
                  type="date"
                  locale={en}
                  defaultValue={''}
                  className="form-control"
                />
              </div>
            </Col>}

            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="boarding_time">{t("boarding_time")}</label>
                <DatePicker
                  selected={boardingTime}
                  onChange={(date) => setBoardingTime(date)}
                  className="form-control"

                  placeholderText={t("boarding_time")}
                  showTimeSelect
                  showTimeSelectOnly

                  timeIntervals={30}
                  timeCaption="Time"
                  locale={ptBR}
                  dateFormat="p"
                  timeFormat="p"

                  type="time"
                />
              </div>
            </Col> 

            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="seats">{t("seats")}</label>
                <input
                  defaultValue={''}
                  type="number"
                  name="seats"
                  id="seats"
                  onChange={handleChange("seats")}
                  className="form-control"

                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="tripType">{t("category")}</label>
                <Select
                  options={tripTypes}
                  onChange={setType}
                  styles={customStyles}
                  
                  type="text"
                  name="tripType"

                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="form-group">
                <label htmlFor="price">{t("price")}</label>
                <input
                  defaultValue={''}
                  type="number"
                  name="price"
                  id="price"
                  className="form-control"
                  onChange={handleChange("price")}
                />
              </div>
            </Col>
          </Row>

          <ModalFooter>
            {loading ? <><SpinnerCircular /><p> {t('loading_message')}...</p></> :
              <>
              <h5>{t('repeat_trip')}</h5>

                <Button color="primary" onClick={addTrip}>
                  {t('day_only')}
                </Button>
               
                <Button color="primary" onClick={addNextDay}>
                  {t('next_day')}
                </Button>
                <Button color="primary" onClick={addWeek}>
                  {t('one_week')}
                </Button>
                <Button color="primary" onClick={addMonth}>
                  {t('one_month')}
                </Button>
                
                <Button
                  type="button"
                  color="light"
                  onClick={() => getModal({ modal_static: false })}
                >
                  {t("cancel")}
                </Button>
               
              </>}

          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default withNamespaces()(TripModal);
